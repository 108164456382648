import React from "react";

export default function InputField(props) {
  return (
    <div className="input-div">
      <label htmlFor="task">{props.heading}</label>
      <div className="input-icon-div">
        <i className={props.icon}></i>{" "}
        <input
          disabled={props.disabled}
          value={props.value} // value passed from parent state
          onChange={(e) => props.setValue(e.target.value)} // setValue will be called to update the state
          className="input-field"
          type={props.type}
          placeholder={props.placeholder}
        />
      </div>
    </div>
  );
}
