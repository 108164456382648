import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./globalSearch.css";

const GlobalSearch = ({ isShowComponent, setIsShowComponent }) => {
  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const inputRef = useRef(null); // Create a ref for the input field

  const searchContainerRef = useRef(null);
  useEffect(() => {
    if (isShowComponent) {
      inputRef.current?.focus(); // Focus the input field
    }
  }, [isShowComponent]);

  // Fetch Data for All Entities
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    try {
      const leadsResponse = await axios.get(process.env.REACT_APP_API_LEADS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const dealsResponse = await axios.get(process.env.REACT_APP_API_DEALS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      const contactsResponse = await axios.get(
        process.env.REACT_APP_API_CONTACTS,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const tasksResponse = await axios.get(process.env.REACT_APP_API_TASKS, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setLeads(leadsResponse.data.leads);
      setDeals(dealsResponse.data.deals);
      setContacts(contactsResponse.data.contacts);
      setTasks(tasksResponse.data.tasks);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Close on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setIsShowComponent(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsShowComponent]);

  // Filter Leads
  const filteredLeads = leads.filter(
    (lead) =>
      lead.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lead.dealName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      lead.mobileNo?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Filter Deals
  const filteredDeals = deals.filter(
    (deal) =>
      deal.dealName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      deal.mobileNo?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Filter Contacts
  const filteredContacts = contacts.filter(
    (contact) =>
      contact.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.email?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Filter Tasks
  const filteredTasks = tasks.filter(
    (task) =>
      task.task?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      task.taskComment?.toLowerCase().includes(searchQuery.toLowerCase())
  );

  if (isShowComponent) {
    return (
      <section className="searchBar_topbar">
        <section className="mn-sec-glbl" ref={searchContainerRef}>
          <div className="global-search">
            <div className="search-bar">
              <input
                ref={inputRef} // Attach the ref to the input field
                type="text"
                placeholder="Search across Leads, Deals, Contacts, Tasks..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="search-results">
              {/* Leads Section */}
              <div className="section">
                <h3>Leads</h3>
                {filteredLeads.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Mobile No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredLeads.map((lead) => (
                        <tr key={lead._id}>
                          <td>{lead.name || "N/A"}</td>
                          <td>{lead.mobileNo || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No matching leads found.</p>
                )}
              </div>

              {/* Deals Section */}
              <div className="section-main">
                <h3>Deals</h3>
                {filteredDeals.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Deal Name</th>
                        <th>Mobile No</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDeals.map((deal) => (
                        <tr key={deal._id}>
                          <td>{deal.dealName}</td>
                          <td>{deal.mobileNo || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No matching deals found.</p>
                )}
              </div>

              {/* Contacts Section */}
              <div className="section">
                <h3>Contacts</h3>
                {filteredContacts.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Mobile No</th>
                        <th>Email</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredContacts.map((contact) => (
                        <tr key={contact._id}>
                          <td>{contact.name}</td>
                          <td>{contact.mobile}</td>
                          <td>{contact.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No matching contacts found.</p>
                )}
              </div>

              {/* Tasks Section */}
              <div className="section">
                <h3>Tasks</h3>
                {filteredTasks.length > 0 ? (
                  <table>
                    <thead>
                      <tr>
                        <th>Task Name</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredTasks.map((task) => (
                        <tr key={task._id}>
                          <td>{task.task}</td>
                          <td>{task.taskComment || "N/A"}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <p>No matching tasks found.</p>
                )}
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  } else {
    return null;
  }
};

export default GlobalSearch;
