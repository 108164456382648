import React from "react";
import "./CustomPieChart.css";

const CustomPieChart = () => {
  // Dummy data for the pie chart
  const data = [
    { label: "New", value: 15 },
    { label: "Working", value: 50 },
    { label: "Pending", value: 20 },
    { label: "Converted", value: 30 },
    { label: "Not Converted", value: 35 },
  ];

  // Total value of all segments
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  // Calculate segments' start and end angles
  let cumulativeValue = 0;
  const segments = data.map((item, index) => {
    const startAngle = (cumulativeValue / totalValue) * 360;
    cumulativeValue += item.value;
    const endAngle = (cumulativeValue / totalValue) * 360;

    return {
      ...item,
      startAngle,
      endAngle,
      color: getColor(index),
    };
  });

  return (
    <div className="pie-chart-container">
      <div className="legend">
        {data.map((item, index) => (
          <div key={index} className="legend-item">
            <span
              className="legend-color"
              style={{ backgroundColor: getColor(index) }}
            ></span>
            <span className="legend-label">{item.label}</span>
          </div>
        ))}
      </div>
      <svg viewBox="-1 -1 2 2" style={{ transform: "rotate(-90deg)" }}>
        {segments.map((segment, index) => (
          <path
            key={index}
            d={describeArc(0, 0, 1, segment.startAngle, segment.endAngle)}
            fill={segment.color}
          />
        ))}
      </svg>
    </div>
  );
};

// Function to dynamically assign colors
const getColor = (index) => {
  const colors = [
    "rgba(243, 65, 0, 0.8)", // Primary brand color (F34100)
    "rgba(0, 119, 255, 0.8)", // Secondary brand color (0077FF)
    "rgba(255, 159, 67, 0.8)", // Complementary color (FF9F43)
    "rgba(52, 199, 89, 0.8)", // Fresh green (34C759)
    "rgba(156, 39, 176, 0.8)", // Purple tone (9C27B0)
  ];
  return colors[index % colors.length];
};

// Function to describe an arc (for pie segments)
const describeArc = (x, y, radius, startAngle, endAngle) => {
  const start = polarToCartesian(x, y, radius, endAngle);
  const end = polarToCartesian(x, y, radius, startAngle);
  const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

  return [
    `M ${start.x} ${start.y}`,
    `A ${radius} ${radius} 0 ${largeArcFlag} 0 ${end.x} ${end.y}`,
    `L ${x} ${y}`,
    "Z",
  ].join(" ");
};

// Convert polar coordinates to Cartesian
const polarToCartesian = (centerX, centerY, radius, angleInDegrees) => {
  const angleInRadians = (angleInDegrees - 90) * (Math.PI / 180.0);
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
};

export default CustomPieChart;
