import React from "react";
import "./FunnelChart.css";

const FunnelChart = () => {
  // Dummy data for the funnel stages
  const data = [
    { label: "Leads Generated", value: 100 },
    { label: "Contacted", value: 80 },
    { label: "In Progress", value: 60 },
    { label: "Negotiation", value: 40 },
    { label: "Closed", value: 20 },
  ];

  const maxValue = Math.max(...data.map((item) => item.value));

  return (
    <section className="funnel-chart">
      {" "}
      <div className="funnel-chart-wrapper">
        {data.map((item, index) => (
          <div
            key={index}
            className="funnel-segment"
            style={{
              width: `${(item.value / maxValue) * 100}%`,
              backgroundColor: getColor(index),
            }}
          >
            <div className="funnel-label">
              {item.label} ({item.value})
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

// Function to dynamically assign colors
const getColor = (index) => {
  const colors = [
    "rgba(243, 65, 0, 0.8)", // Primary brand color
    "rgba(0, 119, 255, 0.8)", // Secondary brand color
    "rgba(255, 159, 67, 0.8)", // Complementary color
    "rgba(52, 199, 89, 0.8)", // Fresh green
    "rgba(156, 39, 176, 0.8)", // Purple tone
  ];
  return colors[index % colors.length];
};

export default FunnelChart;
