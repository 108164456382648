import React, { useEffect, useState } from "react";
import "./customeSelect.css";
import axios from "axios";
import { fetchUserData } from "../Data/UserDet"; // Import the function

const CustomSelect = ({ setSelectedOption_or }) => {
  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const placeholder = "Asign a task for . . . ";
  const [user, setUser] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_LEADS
              : process.env.REACT_APP_API_LEADS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          const url1 =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_DEALS
              : process.env.REACT_APP_API_DEALS;

          const response2 = await axios.get(url1, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          setLeads(response.data.leads); // Set leads
          setDeals(response2.data.deals);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    const getUserAndFetchData = async () => {
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (!user) {
      getUserAndFetchData();
    }
    fetchData();
  }, [user]);

  const optionsfg = [...leads, ...deals].map((item) => ({
    value: item._id,
    label: `${item.name || item.dealName} (${item.mobileNo})`,
  }));
  // Filter options based on search query
  const filteredOptions = optionsfg.filter((option) =>
    option.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelect = (option) => {
    setSelectedOption(option.label);
    setSelectedOption_or(option.value);
    setIsDropdownOpen(false);
    setSearchQuery("");
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle dropdown open/close
  };

  return (
    <div className="custom-select-container">
      {/* Display the selected option or placeholder */}
      <div className="custom-select" onClick={toggleDropdown}>
        <div className="custom-select-header">
          {selectedOption || placeholder}
        </div>
        <div
          className={`custom-select-arrow ${isDropdownOpen ? "open" : ""}`}
        />
      </div>

      {isDropdownOpen && (
        <div className="custom-select-dropdown">
          <input
            type="text"
            className="custom-select-search"
            placeholder="Search..."
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <div className="custom-select-options">
            {filteredOptions.length === 0 ? (
              <div className="no-results">No results found</div>
            ) : (
              filteredOptions.map((option, index) => (
                <div
                  key={index}
                  className="custom-select-option"
                  onClick={() => handleSelect(option)}
                >
                  {option.label}
                </div>
              ))
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSelect;
