// LeadsOptions.js

let leadOptions = {
    statusOptions: "",
    sourceOptions: "",
    requirementOptions: "",
  };
  
  // Function to fetch lead options and update the `leadOptions` object
  export const fetchLeadOptions = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_OPTIONS_LEADS, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Include token if required
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch lead options");
      }
  
      const data = await response.json();
      leadOptions = data.data; // Update the `leadOptions` object with the fetched data
      return leadOptions; // Return updated options for immediate use
    } catch (error) {
      console.error("Error fetching lead options:", error);
      leadOptions = null; // If error occurs, set `leadOptions` to null
      return null; // Return null to indicate failure
    }
  };
  
  // Export the leadOptions object for direct use
  export default leadOptions;
  