import React, { useEffect, useState } from "react";
import "./taskview.css";
import axios from "axios";
import { fetchUserData } from "../Data/UserDet"; // Import the function

import taskOptions, { fetchTaskOptions } from "../Data/Tasksoptions";
import CustomSelect from "./CustomeSelect";
import Toast from "./Toast";
import TaskNotesCom from "./TaskNotesCom";

export default function Taskview({ task, onClose, mode }) {
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");
  const [tasksOptions, setTasksOptions] = useState(taskOptions); // Initialize with the default object
  useEffect(() => {
    const getOptions = async () => {
      const fetchedOptions = await fetchTaskOptions(); // Fetch updated options
      if (fetchedOptions) {
        setTasksOptions(fetchedOptions); // Update state with fetched options
      }
    };
    const fetchUserData = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_USER, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
          },
        });

        if (!response.ok) {
          throw new Error("Failed to fetch user data");
        }

        const data = await response.json();
        setUser(data.user); // Assuming the response contains a 'user' object
      } catch (error) {
        console.log("Error Occured", error);
      }
    };
    if (!taskOptions || taskOptions <= 0 || !user) {
      fetchUserData();
      getOptions(); // Trigger fetch on component mount
    }
  }, [user]);

  const [followUp, setFollowUp] = useState(false);
  const [followtext, setFollowtext] = useState("Follow up");

  const [task_new, setNewTask] = useState();
  const [dueDate, setDueDate] = useState();
  const [dueTask, setDueTask] = useState();
  const [loading, setLoading] = useState(true); // Track loading state

  const [comment, setComment] = useState("");
  const [taskStatus, setTaskStatus] = useState("");

  const [selectedOption, setSelectedOption] = useState(null);

  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);
  // eslint-disable-next-line
  const [lead_ID, setLeadID] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_LEADS
              : process.env.REACT_APP_API_LEADS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          const url1 =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_DEALS
              : process.env.REACT_APP_API_DEALS;

          const response2 = await axios.get(url1, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });
          setLeads(response.data.leads); // Set leads
          setDeals(response2.data.deals);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    if (!leads || !deals || leads.length <= 0 || deals.length <= 0) {
      fetchData();
    }
  }, [user, deals, leads]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      // Ensure token is retrieved correctly from localStorage
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }
        // Simulate a 1-second delay before fetching data
        const response = await axios.get(
          process.env.REACT_APP_API_TOTAL_TASKS,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Send token as Authorization header
            },
          }
        );

        const foundTask = await response.data.tasks.find(
          (taskItem) => taskItem._id === task
        );

        if (foundTask) {
          setDueTask(foundTask); // Set the found task in state
          setLeadID(foundTask.lead_ID); // Set the lead ID from the found task
        } else {
          console.error("No task found with the specified ID:", task);
        }
        setDueTask(foundTask);
        setLeadID(foundTask.lead_ID);

        setComment(foundTask?.taskComment || ""); // Initial comment
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (task) {
      fetchData();
    }
  }, [task]);

  useEffect(() => {
    const getUserAndFetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!user) {
      getUserAndFetchData();
    }
    if (!users) {
      fetchUsers();
    }
    if (user && users) {
      setLoading(false);
    }
  }, [user, users]);
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };
  const formatDueDate = (date) => {
    const dueDate = new Date(date);
    const today = new Date();
    const oneDay = 24 * 60 * 60 * 1000;

    if (dueDate.toDateString() === today.toDateString()) return "Today";
    if (
      dueDate.toDateString() ===
      new Date(today.getTime() + oneDay).toDateString()
    )
      return "Tomorrow";
    if (
      dueDate.toDateString() ===
      new Date(today.getTime() - oneDay).toDateString()
    )
      return "Yesterday";

    return dueDate.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const handleSave = () => {
    const data = {
      taskID: task, // Task ID
      taskComment: comment,
      taskStatus,
    };
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }
    axios
      .post(process.env.REACT_APP_API_UPDATE_TASK, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Task Saved Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };

  const handleNewSave = (followUpLeadID, userId) => {
    const data = {
      userId,
      lead_ID: followUp
        ? followUpLeadID
        : selectedOption
        ? selectedOption
        : "No ID",
      task: task_new,
      taskComment: comment,
      taskStatus,
      taskDueDate: dueDate,
    };
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }
    axios
      .post(process.env.REACT_APP_API_ADD_TASK, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Task Saved Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };

  if (mode === "new") {
    return (
      <>
        <section className="mainSection_tv">
          <div className="mainDiv_tv">
            <div className="topbar_viewLD">
              <h3>Task Panel</h3>
              <div style={{ display: "flex", gap: "6px" }}>
                {" "}
                <span title="Close" onClick={onClose}>
                  <i className="fa-solid fa-xmark fa-lg"></i>
                </span>
              </div>
            </div>

            <div className="taskView_division">
              <div className="taskDetails">
                <span
                  onChange={(e) => {
                    setNewTask(e.target.value);
                  }}
                  className="label"
                >
                  <select
                    onChange={(e) => {
                      setNewTask(e.target.value);
                    }}
                    className="task_new"
                  >
                    <option value="">Add new task</option>
                    {tasksOptions.taskTypesOptions &&
                      tasksOptions.taskTypesOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </span>
                <span className="label">
                  Assignee: {user ? user.userName : "NA"}
                </span>

                <CustomSelect setSelectedOption_or={setSelectedOption} />
                <span className="label">
                  Task Due Date:{" "}
                  <input
                    onChange={(e) => {
                      setDueDate(e.target.value);
                    }}
                    type="date"
                  />
                </span>
                <span className="label">
                  Task Status:{" "}
                  <select
                    onChange={(e) => {
                      setTaskStatus(e.target.value);
                    }}
                    className="styledDropdown"
                    value={taskStatus}
                  >
                    <option value="Select status">Select status</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </select>
                </span>
              </div>

              <div className="descriptionContainer">
                <label>Comment</label>
                <textarea
                  placeholder="What is this task about?"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>

              <button className="saveButton" onClick={handleNewSave}>
                Save
              </button>
              <div
                style={{ width: "100%", textAlign: "center", marginTop: "5px" }}
              >
                {isShowToast ? (
                  <Toast
                    message={toastMessage}
                    messageClass={toastMessageClass}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </section>{" "}
      </>
    );
  } else {
    if (loading) {
      return (
        <>
          <section
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
              position: "fixed",
              top: "0",
              left: "0",
            }}
          >
            <div className="spinner"></div>
          </section>
        </>
      );
    }
    if (!loading && lead_ID) {
      let lead;
      try {
        lead = leads.find((lead) => dueTask.lead_ID === lead._id);
        if (lead === null || lead === undefined) {
          lead = deals.find((deal) => dueTask.lead_ID === deal._id);
        }
      } catch (error) {
        console.log(error);
      }
      if (lead === null || lead === undefined) {
        return null;
      }
      return (
        <section className="mainSection_tv">
          <div className="mainDiv_tv">
            <div className="topbar_viewLD">
              <h3>Task Panel</h3>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "10px",
                  width: "30%",
                }}
              >
                {" "}
                <span
                  className="Create-sub-task button"
                  title="Create sub task"
                >
                  <button
                    onClick={() => {
                      if (followUp) {
                        setFollowUp(false);
                        setFollowtext("Follow up");
                        setComment(dueTask.taskComment);
                        setDueDate(dueTask.dueDate);
                        setNewTask(dueTask.task);
                        setTaskStatus(dueTask.taskStatus);
                      } else {
                        setFollowUp(true);
                        setFollowtext("Cancle");
                        setComment("");
                        setDueDate("");
                        setNewTask("");
                        setTaskStatus("Select status");
                      }
                    }}
                    style={{ gap: "5px" }}
                  >
                    <i className="fas fa-tasks"></i>
                    {followtext}
                  </button>
                </span>
                <span title="Close" onClick={onClose}>
                  <i className="fa-solid fa-xmark fa-lg"></i>
                </span>
              </div>
            </div>

            <div className="taskView_division">
              {!followUp ? (
                mode === "view" ? (
                  <h2>{dueTask ? dueTask.task : "Loading..."}</h2>
                ) : (
                  <h2>Add a new task</h2>
                )
              ) : (
                <h2>Add follow up task</h2>
              )}

              <div className="taskDetails">
                {followUp ? (
                  <select
                    onChange={(e) => {
                      setNewTask(e.target.value);
                    }}
                    className="task_new"
                  >
                    <option value="">Add new task</option>
                    {tasksOptions.taskTypesOptions &&
                      tasksOptions.taskTypesOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                ) : (
                  ""
                )}

                <span className="label">
                  Lead :{" "}
                  {lead
                    ? lead.name || lead.dealName || "No name available"
                    : "No lead data"}
                  ( +91 {lead.mobileNo} )
                </span>
                <span className="label">
                  Assignee: {getUserName(dueTask.userId)}
                </span>
                <span className="label">
                  Task Due Date:{" "}
                  {!followUp ? (
                    dueTask ? (
                      formatDueDate(dueTask.taskDueDate)
                    ) : (
                      "Loading..."
                    )
                  ) : (
                    <>
                      <input
                        onChange={(e) => {
                          setDueDate(e.target.value);
                        }}
                        type="date"
                      />
                    </>
                  )}
                </span>
                <span className="label">
                  Task Status:{" "}
                  <select
                    onChange={(e) => {
                      // Always update the `taskStatus` based on user interaction
                      setTaskStatus(e.target.value);
                    }}
                    className="styledDropdown"
                    value={
                      followUp
                        ? taskStatus // When followUp is true, reflect the taskStatus state
                        : taskStatus ||
                          (dueTask ? dueTask.taskStatus : "loading") // Update dynamically for dueTask
                    }
                  >
                    <option value="Select status">Select status</option>
                    <option value="In Progress">In Progress</option>
                    <option value="Completed">Completed</option>
                    <option value="Pending">Pending</option>
                  </select>
                </span>
              </div>

              <div className="descriptionContainer">
                <label>Comment</label>
                <textarea
                  placeholder="What is this task about?"
                  value={comment}
                  onChange={(e) => {
                    setComment(e.target.value);
                  }}
                />
              </div>

              <button
                className="saveButton"
                onClick={() =>
                  followUp ? handleNewSave(lead._id, lead.userId) : handleSave()
                }
              >
                Save
              </button>
              <div
                style={{ width: "100%", textAlign: "center", marginTop: "5px" }}
              >
                {isShowToast ? (
                  <Toast
                    message={toastMessage}
                    messageClass={toastMessageClass}
                  />
                ) : null}
              </div>
            </div>

            <TaskNotesCom taskId={task} />
          </div>
        </section>
      );
    }
  }
}
