export const countries = [
  {
    name: "India",
    states: [
      "Andhra Pradesh",
      "Arunachal Pradesh",
      "Assam",
      "Bihar",
      "Chhattisgarh",
      "Goa",
      "Gujarat",
      "Haryana",
      "Himachal Pradesh",
      "Jharkhand",
      "Karnataka",
      "Kerala",
      "Madhya Pradesh",
      "Maharashtra",
      "Manipur",
      "Meghalaya",
      "Mizoram",
      "Nagaland",
      "Odisha",
      "Punjab",
      "Rajasthan",
      "Sikkim",
      "Tamil Nadu",
      "Telangana",
      "Tripura",
      "Uttar Pradesh",
      "Uttarakhand",
      "West Bengal",
      "Andaman and Nicobar Islands",
      "Chandigarh",
      "Dadra and Nagar Haveli and Daman and Diu",
      "Lakshadweep",
      "Delhi",
      "Puducherry",
    ],
  },
  {
    name: "USA",
    states: [
      "California",
      "Texas",
      "Florida",
      "New York",
      "Pennsylvania",
      "Illinois",
      "Ohio",
      "Georgia",
      "North Carolina",
      "Michigan",
      "New Jersey",
      "Virginia",
      "Washington",
      "Arizona",
      "Massachusetts",
      "Tennessee",
      "Indiana",
      "Missouri",
      "Maryland",
      "Wisconsin",
      "Colorado",
    ],
  },
  {
    name: "United Kingdom",
    states: ["England", "Scotland", "Wales", "Northern Ireland"],
  },
  {
    name: "Canada",
    states: [
      "Ontario",
      "Quebec",
      "British Columbia",
      "Alberta",
      "Manitoba",
      "Saskatchewan",
      "Nova Scotia",
      "New Brunswick",
      "Prince Edward Island",
      "Newfoundland and Labrador",
    ],
  },
  {
    name: "Australia",
    states: [
      "New South Wales",
      "Queensland",
      "Victoria",
      "Western Australia",
      "South Australia",
      "Tasmania",
      "Australian Capital Territory",
      "Northern Territory",
    ],
  },
  {
    name: "Germany",
    states: [
      "Bavaria",
      "North Rhine-Westphalia",
      "Baden-Württemberg",
      "Hesse",
      "Saxony",
      "Lower Saxony",
      "Rhineland-Palatinate",
      "Berlin",
      "Schleswig-Holstein",
      "Brandenburg",
    ],
  },
  {
    name: "France",
    states: [
      "Île-de-France",
      "Provence-Alpes-Côte d'Azur",
      "Auvergne-Rhône-Alpes",
      "Nouvelle-Aquitaine",
      "Hauts-de-France",
      "Grand Est",
      "Brittany",
      "Normandy",
      "Pays de la Loire",
      "Occitanie",
    ],
  },
  {
    name: "Brazil",
    states: [
      "São Paulo",
      "Rio de Janeiro",
      "Minas Gerais",
      "Bahia",
      "Rio Grande do Sul",
      "Paraná",
      "Pernambuco",
      "Ceará",
      "Santa Catarina",
      "Espírito Santo",
    ],
  },
  {
    name: "Mexico",
    states: [
      "Chihuahua",
      "Jalisco",
      "Veracruz",
      "Puebla",
      "Guanajuato",
      "Nuevo León",
      "Baja California",
      "Durango",
      "Yucatán",
      "Sonora",
    ],
  },
];

export const cities = {
  "Andhra Pradesh": [
    "Amaravati",
    "Visakhapatnam",
    "Vijayawada",
    "Guntur",
    "Tirupati",
    "Kakinada",
    "Rajahmundry",
    "Nellore",
    "Anantapur",
    "Chittoor",
    "Kadapa",
  ],
  "Arunachal Pradesh": ["Itanagar", "Tawang", "Naharlagun", "Bomdila", "Ziro"],
  Assam: [
    "Guwahati",
    "Dibrugarh",
    "Jorhat",
    "Tinsukia",
    "Silchar",
    "Nagaon",
    "Tezpur",
    "Bongaigaon",
  ],
  Bihar: [
    "Patna",
    "Gaya",
    "Bhagalpur",
    "Muzaffarpur",
    "Darbhanga",
    "Begusarai",
    "Purnea",
    "Munger",
  ],
  Chhattisgarh: ["Raipur", "Bilaspur", "Durg", "Korba", "Raigarh", "Jagdalpur"],
  Goa: ["Panaji", "Vasco da Gama", "Margao", "Mapusa", "Ponda"],
  Gujarat: [
    "Ahmedabad",
    "Surat",
    "Vadodara",
    "Rajkot",
    "Bhavnagar",
    "Jamnagar",
    "Anand",
    "Bhuj",
  ],
  Haryana: [
    "Chandigarh",
    "Faridabad",
    "Gurugram",
    "Ambala",
    "Panipat",
    "Sonipat",
    "Karnal",
  ],
  "Himachal Pradesh": [
    "Shimla",
    "Dharamsala",
    "Kullu",
    "Manali",
    "Solan",
    "Mandi",
  ],
  Jharkhand: [
    "Ranchi",
    "Jamshedpur",
    "Dhanbad",
    "Hazaribagh",
    "Deoghar",
    "Bokaro",
    "Giridih",
  ],
  Karnataka: [
    "Bengaluru",
    "Mysuru",
    "Hubli",
    "Mangalore",
    "Belagavi",
    "Davanagere",
    "Ballari",
    "Shimoga",
    "Mandya",
    "Tumkur",
    "Udupi",
  ],
  Kerala: [
    "Thiruvananthapuram",
    "Kochi",
    "Kozhikode",
    "Kottayam",
    "Thrissur",
    "Alappuzha",
    "Malappuram",
  ],
  "Madhya Pradesh": [
    "Bhopal",
    "Indore",
    "Gwalior",
    "Jabalpur",
    "Ujjain",
    "Sagar",
    "Ratlam",
    "Rewa",
  ],
  Maharashtra: [
    "Mumbai",
    "Pune",
    "Nagpur",
    "Nashik",
    "Aurangabad",
    "Thane",
    "Kolhapur",
    "Solapur",
    "Satara",
    "Ratnagiri",
    "Amravati",
    "Jalgaon",
  ],
  Manipur: ["Imphal", "Thoubal", "Churachandpur"],
  Meghalaya: ["Shillong", "Tura", "Jowai", "Nongstoin"],
  Mizoram: ["Aizawl", "Lunglei", "Champhai"],
  Nagaland: ["Kohima", "Dimapur", "Mokokchung", "Tuensang"],
  Odisha: ["Bhubaneswar", "Cuttack", "Rourkela", "Berhampur", "Sambalpur"],
  Punjab: [
    "Chandigarh",
    "Amritsar",
    "Ludhiana",
    "Jalandhar",
    "Patiala",
    "Mohali",
    "Bathinda",
  ],
  Rajasthan: [
    "Jaipur",
    "Udaipur",
    "Jodhpur",
    "Kota",
    "Ajmer",
    "Bikaner",
    "Alwar",
    "Bhilwara",
    "Pali",
    "Churu",
    "Sikar",
    "Jaisalmer",
    "Dungarpur",
    "Tonk",
    "Nagaur",
    "Barmer",
    "Jhalawar",
    "Hanumangarh",
    "Bundi",
    "Sawai Madhopur",
  ],
  Sikkim: ["Gangtok", "Namchi", "Mangan"],
  "Tamil Nadu": [
    "Chennai",
    "Coimbatore",
    "Madurai",
    "Tiruchirappalli",
    "Salem",
    "Erode",
    "Tirunelveli",
    "Vellore",
    "Dharmapuri",
    "Karur",
    "Ramanathapuram",
  ],
  Telangana: [
    "Hyderabad",
    "Warangal",
    "Khammam",
    "Karimnagar",
    "Nizamabad",
    "Mahabubnagar",
  ],
  Tripura: ["Agartala", "Udaipur", "Belonia"],
  "Uttar Pradesh": [
    "Lucknow",
    "Kanpur",
    "Agra",
    "Varanasi",
    "Meerut",
    "Ghaziabad",
    "Allahabad",
    "Aligarh",
    "Noida",
    "Mathura",
    "Moradabad",
    "Bareilly",
    "Gorakhpur",
    "Muzaffarnagar",
  ],
  Uttarakhand: ["Dehradun", "Haridwar", "Nainital", "Rishikesh", "Rudrapur"],
  "West Bengal": [
    "Kolkata",
    "Siliguri",
    "Durgapur",
    "Asansol",
    "Howrah",
    "Kharagpur",
    "Malda",
    "Shibpur",
  ],
  "Andaman and Nicobar Islands": ["Port Blair", "Car Nicobar", "Mayabunder"],
  Chandigarh: ["Chandigarh"],
  "Dadra and Nagar Haveli and Daman and Diu": ["Daman", "Diu", "Silvassa"],
  Lakshadweep: ["Kavaratti", "Minicoy"],
  Delhi: ["New Delhi", "Old Delhi", "Dwarka", "Rohini", "Pitampura"],
  Puducherry: ["Puducherry", "Karaikal", "Mahe", "Yanam"],
};
