import React, { useEffect, useState } from "react";
import "./invoices.css";

export default function Invoices() {
  const [currentTime, setCurrentTime] = useState("");
  const [activeTab, setActiveTab] = useState("invoices"); // Track active tab

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleString("en-US", {
        dateStyle: "medium",
        timeStyle: "short",
      });
      setCurrentTime(formattedTime);
    }, 1000);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      <section className="mainTaskSection">
        {/* Topbar */}
        <div className="topbar_main_tsk">
          <h1
            style={{
              display: "flex",
              gap: "5px",
              alignContent: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <i class="fa-solid fa-file-invoice"></i>
            Manage Your Invoices and Quotations
          </h1>
          <div className="topbar_right">
            <div className="date_time">
              <i className="fa-solid fa-clock"></i>{" "}
              <span>{currentTime ? currentTime : "loading..."}</span>
            </div>
          </div>
        </div>
        <div className="mainDivision_TaskPage">
          <div className="topbar-btns">
            {" "}
            <div className="tabs-container">
              <div
                className={`tab ${activeTab === "dashboard" ? "active" : ""}`}
                onClick={() => handleTabClick("dashboard")}
              >
                Dashboard
              </div>
              <div
                className={`tab ${activeTab === "invoices" ? "active" : ""}`}
                onClick={() => handleTabClick("invoices")}
              >
                Invoices
              </div>

              <div
                className={`tab ${activeTab === "quotations" ? "active" : ""}`}
                onClick={() => handleTabClick("quotations")}
              >
                Quotations
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              <button>
                <i className="fa-solid fa-plus"></i> Create Invoice
              </button>
              <button>
                <i className="fa-solid fa-plus"></i> Create Quotation
              </button>
            </div>
          </div>

          {/* Tab Content */}
          <div className="tab-content-blg">
            {activeTab === "dashboard" && (
              <div className="table-container">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    padding: "10px",
                  }}
                >
                  <span>Sample Dashboard</span>
                </div>
              </div>
            )}
            {activeTab === "invoices" && (
              <div className="table-container">
                <table className="invoices-table">
                  <thead>
                    <tr>
                      <th>Invoice No.</th>
                      <th>Customer Name</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Due Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example rows */}
                    <tr>
                      <td>#001</td>
                      <td>John Doe</td>
                      <td>$100</td>
                      <td>Paid</td>
                      <td>01/01/2024</td>
                    </tr>
                    <tr>
                      <td>#002</td>
                      <td>Jane Smith</td>
                      <td>$200</td>
                      <td>Unpaid</td>
                      <td>05/01/2024</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            {activeTab === "quotations" && (
              <div className="table-container">
                <table className="quotations-table">
                  <thead>
                    <tr>
                      <th>Quotation No.</th>
                      <th>Customer Name</th>
                      <th>Amount</th>
                      <th>Status</th>
                      <th>Expiration Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/* Example rows */}
                    <tr>
                      <td>#A001</td>
                      <td>Michael Johnson</td>
                      <td>$150</td>
                      <td>Pending</td>
                      <td>03/01/2024</td>
                    </tr>
                    <tr>
                      <td>#A002</td>
                      <td>Alice Brown</td>
                      <td>$300</td>
                      <td>Accepted</td>
                      <td>07/01/2024</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
