import React, { useState, useMemo, useEffect } from "react";
import "./createdeal.css";
import { countries } from "../Data/States";
import { cities } from "../Data/States";
import InputField from "./InputField";
import axios from "axios";
import dealOptions, { fetchDealOptions } from "../Data/Dealsoptions"; // Import the options and fetch function
import taskOptions, { fetchTaskOptions } from "../Data/Tasksoptions";
import Toast from "./Toast";
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function CreateDeal({
  deal_ID,
  isViewDeal,
  setIsViewDeal,
  mode,
}) {
  const [statusOptions, setStatusOptions] = useState([]);
  const [user, setUser] = useState(null);

  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");
  const [dealsOptions, setDealOptions] = useState(dealOptions); // Initialize with the default object

  useEffect(() => {
    const getOptions = async () => {
      const fetchedOptions = await fetchDealOptions(); // Fetch updated options
      if (fetchedOptions) {
        setDealOptions(fetchedOptions); // Update state with fetched options
      }
    };

    getOptions(); // Trigger fetch on component mount
  }, []); // Empty dependency array means this effect runs only once when the component mounts
  const [tasksOptions, setTasksOptions] = useState(taskOptions); // Initialize with the default object
  useEffect(() => {
    const getOptions = async () => {
      const fetchedOptions = await fetchTaskOptions(); // Fetch updated options
      if (fetchedOptions) {
        setTasksOptions(fetchedOptions); // Update state with fetched options
      }
    };

    getOptions(); // Trigger fetch on component mount
  }, []);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(true);
  const allDealsURL = process.env.REACT_APP_API_DEALS;

  useEffect(() => {
    const fetchLeadData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_DEALS
              : process.env.REACT_APP_API_DEALS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          const foundDeal = response.data.deals.find((l) => l._id === deal_ID);

          if (foundDeal) {
            setDealName(foundDeal.dealName);
            setMobileNo(foundDeal.mobileNo);
            setEmail(foundDeal.email);
            setCreationDate(
              new Date(foundDeal.creationDate).toISOString().slice(0, 10)
            );

            setStage(foundDeal.stage);
            const initialIndex = statusOptions.indexOf(foundDeal.stage);
            setActiveStatusIndex(initialIndex > -1 ? initialIndex : 0);
            // ... other state updates
            setDealType(foundDeal.dealType);
            setDealSource(foundDeal.dealSource);
            setRequirement(foundDeal.requirement);
            setSelectedCountry(foundDeal.selectedCountry); // Set country
            setSelectedState(foundDeal.selectedState); // Set state
            setSelectedCity(foundDeal.selectedCity); // Set city
            setNotes(foundDeal.notes); // Set notes
          }
        }

        const response = await axios.get(allDealsURL, {
          headers: {
            Authorization: `Bearer ${token}`, // Send token as Authorization header
          },
        });
        const foundDeal = response.data.deals.find((l) => l._id === deal_ID);

        if (foundDeal) {
          setDealName(foundDeal.dealName);
          setMobileNo(foundDeal.mobileNo);
          setEmail(foundDeal.email);
          setCreationDate(
            new Date(foundDeal.creationDate).toISOString().slice(0, 10)
          );

          setStage(foundDeal.stage);
          const initialIndex = statusOptions.indexOf(foundDeal.stage);
          setActiveStatusIndex(initialIndex > -1 ? initialIndex : 0);
          // ... other state updates
          setDealType(foundDeal.dealType);
          setDealSource(foundDeal.dealSource);
          setRequirement(foundDeal.requirement);
          setSelectedCountry(foundDeal.selectedCountry); // Set country
          setSelectedState(foundDeal.selectedState); // Set state
          setSelectedCity(foundDeal.selectedCity); // Set city
          setNotes(foundDeal.notes); // Set notes
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
      } finally {
        setLoading(false);
      }
    };
    const resetData = () => {
      setDealName("");
      setMobileNo("");
      setEmail("");
      setCreationDate("");
      setStage("New");
      setDealType("");
      setDealSource("");
      setRequirement("");
      setSelectedCountry("India");
      setSelectedState("");
      setSelectedCity("");
      setNotes("");
      setActiveStatusIndex(0);
    };
    const getUserAndFetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };
    if (!user) {
      getUserAndFetchData();
    }
    if (deal_ID && mode === "edit") {
      fetchLeadData();
    } else {
      resetData();
    } // eslint-disable-next-line
    if (dealsOptions.stagesOptions) {
      setStatusOptions(dealsOptions.stagesOptions); // Assign fetched data to statusOptions
    }
  }, [
    deal_ID,
    allDealsURL,
    mode,
    isViewDeal,
    statusOptions,
    dealsOptions,
    user,
  ]); // Include isViewDeal in dependency array

  const [activeStatusIndex, setActiveStatusIndex] = useState(0); // Track highest reached status

  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStage(newStatus);
    const newIndex = statusOptions.indexOf(newStatus);

    // Update activeStatusIndex to reflect the current and all previous stages as active
    if (newIndex > -1) setActiveStatusIndex(newIndex);
  };
  const stateOptions = useMemo(() => {
    return countries.find((c) => c.name === selectedCountry)?.states || [];
  }, [selectedCountry]);

  const cityOptions = useMemo(
    () => cities[selectedState] || [],
    [selectedState]
  );

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedState(""); // Reset state when country changes
    setSelectedCity(""); // Reset city when country changes
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity(""); // Reset city when state changes
  };

  const [dealName, setDealName] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [email, setEmail] = useState("");
  const [creationDate, setCreationDate] = useState("");
  const [stage, setStage] = useState("New");
  const [dealType, setDealType] = useState("");
  const [dealSource, setDealSource] = useState("");
  const [requirement, setRequirement] = useState("");
  const [notes, setNotes] = useState("");
  const [task, setTask] = useState("");
  const [taskDate, setTaskDate] = useState("");
  const [comment, setComment] = useState("");

  const dealURL = process.env.REACT_APP_API_ADD_DEAL;

  const handleSave = async () => {
    const dealData = {
      dealName,
      mobileNo,
      email,
      creationDate,
      stage,
      dealType,
      dealSource,
      requirement,
      selectedCountry,
      selectedState,
      selectedCity,
      notes,
      task,
      taskComment: comment, // Adjusted field name for backend compatibility
      taskStatus: "In Progress", // Assuming a default task status
      taskDueDate: taskDate, // Adjusted field name for backend compatibility
    };
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }
    axios
      .post(dealURL, dealData, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Deal Saved Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };
  const handleUpdate = async () => {
    const dealData = {
      deal_ID,
      dealName,
      mobileNo,
      email,
      creationDate,
      stage,
      dealType,
      dealSource,
      requirement,
      selectedCountry,
      selectedState,
      selectedCity,
      notes,
      task,
      taskComment: comment, // Adjusted field name for backend compatibility
      taskStatus: "In Progress", // Assuming a default task status
      taskDueDate: taskDate, // Adjusted field name for backend compatibility
    };
    console.log(dealData);
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }

    axios
      .post(process.env.REACT_APP_API_UPDATE_DEAL, dealData, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Deal Updated Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };

  if (isViewDeal) {
    return (
      <>
        <section className="mainSectionDeal">
          <div className="mainDivDeal">
            <div className="topbar-add-dl">
              <h3>{mode === "view" ? "Deal Information" : "Create Deal"}</h3>
              <i
                onClick={() => {
                  setIsViewDeal(false);
                }}
                className="fa-solid fa-xmark fa-lg"
              ></i>
            </div>

            {loading ? (
              <>
                <div className="form-container">
                  <div className="leftDiv ad-dl-dv">
                    <div className="skelton-container">
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                    </div>
                  </div>
                  <div className="rightDiv ad-dl-dv">
                    <div className="skelton-container">
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item skeleton-item-btn"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {" "}
                {/* Deal Stage Progression Tracker */}
                <div className="dealProgressionTracker">
                  <h3>Deal Progression</h3>
                  <div className="statusContainer">
                    {dealsOptions.stagesOptions.map((stage, idx) => (
                      <div
                        key={idx}
                        className={`statusItem ${
                          idx <= activeStatusIndex ? "activeStatus" : ""
                        } ${
                          stage === "Closed - Won" && idx <= activeStatusIndex
                            ? "wonStatus"
                            : ""
                        } ${
                          stage === "Closed - Lost" && idx <= activeStatusIndex
                            ? "lostStatus"
                            : ""
                        }`}
                      >
                        {stage}
                      </div>
                    ))}
                  </div>
                </div>{" "}
                <div className="form-container">
                  {/* Left Div */}
                  <div className="leftDiv ad-dl-dv">
                    {/* Deal Name */}
                    <InputField
                      heading="Deal name"
                      icon="fa-solid fa-user"
                      type="text"
                      value={dealName}
                      setValue={setDealName}
                      placeholder="Enter name"
                    />
                    <InputField
                      heading="Mobile"
                      icon="fa-solid fa-phone"
                      type="text"
                      value={mobileNo}
                      setValue={setMobileNo}
                      placeholder="Enter mobile"
                    />
                    <InputField
                      heading="Email"
                      icon="fa-solid fa-envelope"
                      type="text"
                      value={email}
                      setValue={setEmail}
                      placeholder="Enter email"
                    />
                    <InputField
                      heading="Creation date"
                      icon="fa-solid fa-calendar"
                      type="date"
                      value={creationDate}
                      setValue={setCreationDate}
                      disabled ={true}
                    />

                    {/* Stage */}
                    <div className="input-div">
                      <label htmlFor="stage">Stage</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-layer-group"></i>
                        <select
                          className="input-field"
                          onChange={handleStatusChange}
                          value={stage}
                        >
                          {statusOptions.map((option, idx) => (
                            <option key={idx} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    {/* Deal Type */}
                    <div className="input-div">
                      <label htmlFor="dealType">Deal Type</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-tags"></i>
                        <select
                          onChange={(e) => {
                            setDealType(e.target.value);
                          }}
                          value={dealType}
                          className="input-field"
                        >
                          <option value="">Select type</option>
                          {dealsOptions.typeOptions &&
                            dealsOptions.typeOptions.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>

                    {/* Deal Source */}
                    <div className="input-div">
                      <label htmlFor="dealSource">Deal Source</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-link"></i>
                        <select
                          value={dealSource}
                          onChange={(e) => {
                            setDealSource(e.target.value);
                          }}
                          className="input-field"
                        >
                          <option value="">Select source</option>
                          {dealsOptions.sourceOptions &&
                            dealsOptions.sourceOptions.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="dealSource">Requirement</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-link"></i>
                        <select
                          value={requirement}
                          onChange={(e) => {
                            setRequirement(e.target.value);
                          }}
                          className="input-field"
                        >
                          <option value="">Select requirement</option>
                          {dealsOptions.requirementOptions &&
                            dealsOptions.requirementOptions.map(
                              (option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>
                  </div>

                  {/* Right Div */}
                  <div className="rightDiv ad-dl-dv">
                    <div className="input-div">
                      <label htmlFor="country">Country</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-globe"></i>
                        <select
                          className="input-field"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                        >
                          {countries.map((country, idx) => (
                            <option key={idx} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="state">State</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-map"></i>
                        <select
                          className="input-field"
                          value={selectedState}
                          onChange={handleStateChange}
                        >
                          <option value="">Select State</option>
                          {stateOptions.map((state, idx) => (
                            <option key={idx} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="city">City</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-city"></i>
                        <select
                          className="input-field"
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.target.value)}
                        >
                          <option value="">Select City</option>
                          {cityOptions.map((city, idx) => (
                            <option key={idx} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="notes">Notes</label>
                      <textarea
                        value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        id="notes"
                        placeholder="Enter notes"
                        className="textarea-field"
                      ></textarea>
                    </div>
                    <div className="input-div">
                      <label htmlFor="task">Task</label>
                      <div className="input-icon-div">
                        <i class="fa-solid fa-list-check"></i>{" "}
                        <select
                          onChange={(e) => {
                            setTask(e.target.value);
                          }}
                          className="task_new"
                        >
                          <option value="">Add new task</option>
                          {tasksOptions.taskTypesOptions &&
                            tasksOptions.taskTypesOptions.map(
                              (option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>
                    <InputField
                      heading="Due date"
                      icon="fa-solid fa-calendar"
                      type="date"
                      value={taskDate}
                      setValue={setTaskDate}
                    />
                    <div className="input-div">
                      <label htmlFor="notes">Comment</label>
                      <textarea
                        value={comment}
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                        id="notes"
                        placeholder="Enter comment"
                        className="textarea-field"
                      ></textarea>
                    </div>
                    {mode === "new" ? (
                      <button onClick={handleSave} className="btn-save-deal">
                        Save
                      </button>
                    ) : mode === "edit" ? (
                      <>
                        <div className="button-container">
                          {" "}
                          <button onClick={handleUpdate} className="saveButton">
                            Update
                          </button>
                          <button
                            onClick={() => {
                              if (!mobileNo) {
                                alert("Customer mobile number is missing!");
                                return;
                              }

                              const message = `✨ नमस्ते ${dealName}, ✨\nहमारी कंपनी, XYZ Industries Pvt. Ltd., जो कि 📍 123, Industrial Area, Dummy City, 456789 पर स्थित है, आपकी सेवा के लिए हमेशा तत्पर है। 🚀\n🔄 आपकी आवश्यकता: "${requirement}" को सफलतापूर्वक अपडेट कर दिया गया है। ✅\n📋 कृपया अधिक जानकारी के लिए तुरंत जांच करें।\nआपकी "${requirement}" संबंधी जानकारी मैं आपको भेज रहा हूँ। ⏳ कृपया थोड़ा इंतज़ार करें। धन्यवाद! 🙏\n💼 सादर, XYZ Industries Pvt. Ltd. 🌟`;

                              const encodedMessage =
                                encodeURIComponent(message);

                              // Force WhatsApp Web
                              const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobileNo}&text=${encodedMessage}`;

                              // Open WhatsApp Web
                              window.open(whatsappWebUrl);
                            }}
                            className="saveButton whatsappButton"
                          >
                            <i
                              className="fa-brands fa-whatsapp"
                              style={{ marginRight: "8px" }}
                            ></i>
                            Send WhatsApp
                          </button>{" "}
                        </div>
                      </>
                    ) : null}{" "}
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                    >
                      {isShowToast ? (
                        <Toast
                          message={toastMessage}
                          messageClass={toastMessageClass}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </>
    );
  }
}
