import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./scspg.css";

export default function SuccessPage() {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 2000); // Redirect after 1 second

    return () => clearTimeout(timer); // Cleanup timer
  }, [navigate]);

  return (
    <section className="UserInfoPage_MainSection">
      <div className="userInfoForm successContent">
        <div className="icon_Div_Logo">
          <i className="fas fa-check-circle" style={{ color: "#28a745", fontSize: "5em" }}></i>
          <h2>Company Profile Created Successfully!</h2>
          <p>Redirecting to your account setup...</p>
          <i className="fas fa-spinner fa-spin" style={{ color: "#f34100", fontSize: "2em", marginTop: "10px" }}></i>
        </div>
      </div>
    </section>
  );
}
