// Import necessary dependencies and styles
import React, { useEffect, useState } from "react";
import "./navbarmain.css";
import { Link } from "react-router-dom";
import CrmSettingsComp from "./CrmSettingsComp";

import { fetchCompanyData } from "../Data/CompanyDet"; // Import the function
import UsersRoles from "./UsersRoles";

// Functional component for NavigationBar
export default function NavigationBar() {
  const [isShowSettings, setIsShowSettings] = useState(false);
  const [companyDetails, setCompanyDetails] = useState([]);
  const [isShowUserRoles, setIsShowUserRoles] = useState(false);

  useEffect(() => {
    // Call the function to fetch the company data
    const getCompanyData = async () => {
      const data = await fetchCompanyData();
      if (data) {
        setCompanyDetails(data); // Update the state with the fetched company data
      }
    };

    getCompanyData();
  }, []);
  return (
    <>
      <CrmSettingsComp
        isShowSettings={isShowSettings}
        setIsShowSettings={setIsShowSettings}
      />
      <UsersRoles
        isShowUserRoles={isShowUserRoles}
        setIsShowUserRoles={setIsShowUserRoles}
      />
      <section>
        {/* Main navigation div */}
        <div className="mainDiv_Navbar">
          {" "}
          {/* Main Navigation Links */}
          <ul>
            <li>
              <Link
                style={{
                  fontSize: "18px",
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <i className="fa-solid fa-city "></i>
                {companyDetails ? companyDetails.companyName : "loading..."}
              </Link>
            </li>
            <div className="dvd"></div>

            <span>Main</span>
            <li>
              {/* Link to Dashboard */}
              <Link to={"/dashboard"}>
                <i className="fa-solid fa-chart-line"></i>Dashboard
              </Link>
            </li>
            <li>
              {/* Link to Leads */}
              <Link to={"/leads"}>
                <i className="fa-solid fa-user"></i>Leads
              </Link>
            </li>
            <li>
              {/* Link to Deals */}
              <Link to={"/deals"}>
                <i className="fa fa-handshake"></i>Deals
              </Link>
            </li>
            <li>
              {/* Link to Quotations */}
              <Link to={"/invoices"}>
                <i className="fa fa-file-invoice-dollar"></i>Invoices &
                Quotations
              </Link>
            </li>
            <li>
              {/* Link to Contacts */}
              <Link to={"/contacts"}>
                <i className="fa-solid fa-address-book"></i>Contacts
              </Link>
            </li>
            <li>
              {/* Link to Tasks */}
              <Link to={"/tasks"}>
                <i className="fa-solid fa-list-check"></i>Tasks
              </Link>
            </li>
            <li>
              {/* Link to Messages */}
              <Link to={"/inbox"} className="inbox-link">
                <i className="fa-solid fa-message"></i> Inbox
              </Link>
            </li>
          </ul>
          {/* Divider */}
          {/* Insights Section */}
          <ul>
            <div className="dvd"></div>

            <span>Insights</span>
            <li>
              {/* Link to KPI Metrics */}
              <Link to={"/kpis"}>
                <i className="fa fa-chart-bar"></i>KPI Metrics
              </Link>
            </li>
            <li>
              {/* Link to Gamification */}
              <Link to={"/gamifications"}>
                <i className="fa fa-medal"></i> Gamifications
              </Link>
            </li>
          </ul>
          {/* Divider */}
          <div className="dvd"></div>
          {/* Integrations Section */}
          <ul>
            {" "}
            <div className="dvd"></div>
            <span>Integrations</span>
            <li>
              {/* Link to Social Media Integration */}
              <Link to={"/integrations"}>
                <i className="fa fa-link"></i>Website & Social Forms
              </Link>
            </li>
            <li>
              {/* Link to AI Features */}
              <Link to={"/ai"}>
                <i className="fa fa-brain"></i>AI Features
              </Link>
            </li>
          </ul>
          {/* Divider */}
          {/* Branding Section */}
          <ul>
            {" "}
            <div className="dvd"></div>
            <span>Branding</span>
            <li>
              {/* Link to Color Theme */}
              <Link to={"/theme"}>
                <i className="fa fa-palette"></i>Color Theme
              </Link>
            </li>
          </ul>
          {/* Settings Section */}
          <ul>
            {" "}
            <div className="dvd"></div>
            <span>Settings</span>
            <li>
              {/* Link to User Roles */}
              <Link
                onClick={() => {
                  setIsShowUserRoles(true);
                }}
              >
                <i className="fa fa-users-cog"></i>Users & Roles
              </Link>
            </li>
            <li>
              {/* Link to Profiles */}
              <Link
                onClick={() => {
                  setIsShowSettings(true);
                }}
              >
                <i className="fa fa-user-cog"></i>Settings & Profiles
              </Link>
            </li>
          </ul>
          {/* Plan Details Section */}
          <div className="planDet">
            <span>Plan Details:</span>
            <span>
              <span style={{ color: "orangered" }}>Growth Starter</span> <br />
              Unlock New Heights
            </span>
          </div>
        </div>
      </section>
    </>
  );
}
