import React from "react";
import "./toastCss.css";

export default function Toast({ message, messageClass }) {
  return (
    <>
      <p className={messageClass}>
        {messageClass === "success" ? (
          <>
            <i className="fa-solid fa-circle-check icon"></i>{" "}
          </>
        ) : (
          <>
            <i className="fas fa-times-circle icon"></i>{" "}
          </>
        )}
        {message}
      </p>
    </>
  );
}
