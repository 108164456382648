import React, { useEffect, useState } from "react";
import "./deals.css";
import CreateDeal from "../Components/CreateDeal";
import axios from "axios";
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function Deals() {
  const [loading, setLoading] = useState(true);
  const [dlmd, setDlmd] = useState("new");
  const [dealID, setDealID] = useState();
  const [isViewDeal, setIsViewDeal] = useState(false);
  const [deals, setDeals] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_DEALS
              : process.env.REACT_APP_API_DEALS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          setDeals(response.data.deals);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const getUserAndFetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!users) {
      fetchUsers();
    }
    if (!deals || deals.length <= 0) {
      fetchAllData();
    }

    if (!user) {
      getUserAndFetchData();
    }
    if (user && users && deals) {
      setLoading(false);
    }
  }, [user, deals, users]); // `user` triggers fetching only when it changes
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };

  const filteredLeads = deals.filter((deal) =>
    Object.values(deal).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <>
      <CreateDeal
        deal_ID={dealID}
        setIsViewDeal={setIsViewDeal}
        isViewDeal={isViewDeal}
        mode={dlmd}
      />
      <section className="dlsPG_MNSC">
        <div className="dlsPG_MNSC__container__header">
          <h3>📊 All Deals in One Place</h3>
        </div>
        <div className="dlsPG_MNSC__container">
          <div className="dlsPG_MNSC__container__topbar">
            <div className="search-deals-inpt">
              <i className="fa-solid fa-magnifying-glass"></i>
              <input
                onChange={(e) => {
                  setSearchQuery(e.target.value);
                }}
                type="text"
                name="searchleads"
                id="searchleads"
                placeholder="Search from deals"
                className="search-input"
              />
            </div>
            <div className="filterIcns_div">
              <button
                onClick={() => {
                  if (isViewDeal) {
                    setIsViewDeal(false);
                    setDlmd("new");
                  } else {
                    setIsViewDeal(true);
                    setDlmd("new");
                  }
                }}
              >
                <i className="fa-solid fa-circle-plus fa-lg"></i>
                Create Deal
              </button>
            </div>
          </div>
          {/* Topbar Ended */}
          <div className="dlsinfr_dv">
            <table>
              <tbody>
                <tr>
                  <th>Name</th>
                  <th>Mobile No.</th>
                  <th>Created by</th>
                  <th>Stage</th>
                  <th>Deal type</th>
                  <th>City</th>
                  <th>State</th>
                </tr>

                {loading ? (
                  <>
                    {Array(7)
                      .fill(null)
                      .map((_, index) => (
                        <tr key={index}>
                          {Array(7)
                            .fill(null)
                            .map((_, colIndex) => (
                              <td key={colIndex}>
                                <div className="skeleton skeleton-item"></div>
                              </td>
                            ))}
                        </tr>
                      ))}
                  </>
                ) : (
                  <>
                    {filteredLeads.map((deal) => {
                      return (
                        <>
                          <tr
                            onClick={() => {
                              setDealID(deal._id);
                              setDlmd("edit");
                              setIsViewDeal(true);
                            }}
                          >
                            <td>{deal.dealName}</td>
                            <td>{deal.mobileNo}</td>
                            <td>{getUserName(deal.userId)}</td>

                            <td>{deal.stage}</td>
                            <td>{deal.dealType}</td>
                            <td>{deal.selectedCity}</td>
                            <td>{deal.selectedState}</td>
                          </tr>
                        </>
                      );
                    })}
                  </>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </>
  );
}
