import React, { useState, useMemo, useEffect } from "react";
import "./createlead.css";
import { countries } from "../Data/States";
import { cities } from "../Data/States";
import axios from "axios";
import Confetti from "react-confetti";

import leadOptions, { fetchLeadOptions } from "../Data/Leadoptions";
import taskOptions, { fetchTaskOptions } from "../Data/Tasksoptions";
import Toast from "./Toast";
import { fetchUserData } from "../Data/UserDet"; // Import the function
import { useNavigate } from "react-router-dom";

export default function CreateLead({ mode, lead_ID }) {
  const navigate = useNavigate();
  const [showConfetti, setShowConfetti] = useState(false);
  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");
  const [activeStatusIndex, setActiveStatusIndex] = useState(0); // Track highest reached status
  const [user, setUser] = useState(null);

  const [leadsOptions, setLeadOptions] = useState(leadOptions); // Initialize with the default object
  const [tasksOptions, setTasksOptions] = useState(taskOptions); // Initialize with the default object

  const [loading, setLoading] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [status, setStatus] = useState("Open - Not Contacted");
  const [notes, setNotes] = useState([]);
  const [taskDueDate, setTaskDueDate] = useState([]);

  const [name, setName] = useState("");
  const [mobileNo, setMobileNo] = useState("");

  const [creationDate, setCreationDate] = useState("");
  const [assignedTo, setAssignedTo] = useState(""); // Assigned to
  const [requirement, setRequirement] = useState("");
  const [leadSource, setLeadSource] = useState("");
  const [task, setTask] = useState("");
  const [taskComment, setTaskComment] = useState("");
  const [users, setUsers] = useState([]); // Users list

  const [statusOptions, setStatusOptions] = useState([]);

  useEffect(() => {
    const getOptionsTasks = async () => {
      try {
        setLoading(true);
        const fetchedOptions = await fetchTaskOptions(); // Fetch updated options
        if (fetchedOptions) {
          setTasksOptions(fetchedOptions); // Update state with fetched options
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const getOptionsLeads = async () => {
      try {
        setLoading(true);
        const fetchedOptions = await fetchLeadOptions(); // Fetch updated options
        if (fetchedOptions) {
          setLeadOptions(fetchedOptions); // Update state with fetched options
          setStatusOptions(fetchedOptions.statusOptions); // Assign fetched data to statusOptions
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchLeadData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_LEADS
              : process.env.REACT_APP_API_LEADS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          const foundLead = await response.data.leads.find(
            (l) => l._id === lead_ID
          );

          if (foundLead) {
            setName(foundLead.name);
            setMobileNo(foundLead.mobileNo);
            setCreationDate(
              new Date(foundLead.creationDate).toISOString().slice(0, 10)
            );

            setRequirement(foundLead.requirement);
            setLeadSource(foundLead.leadSource);
            setStatus(foundLead.status);
            const initialIndex = statusOptions.indexOf(foundLead.status);
            setActiveStatusIndex(initialIndex > -1 ? initialIndex : -1);
            setNotes(foundLead.notes);
            setSelectedCountry(foundLead.selectedCountry);
            setSelectedState(foundLead.selectedState);
            setSelectedCity(foundLead.selectedCity);
            if (users && users.length > 0) {
              // Filter users to find matches
              const matchedUsers = users.filter(
                (user) => user._id === foundLead.userId
              );

              if (matchedUsers.length > 0) {
                const matchedUser = matchedUsers[0];
                setAssignedTo(matchedUser._id);
              } else {
                console.error("No matching user found for the given lead.");
              }
            }
          }
        }
      } catch (error) {
        console.error("Error fetching lead data:", error);
      } finally {
        setLoading(false);
      }
    };
    const getUserAndFetchData = async () => {
      try {
        setLoading(true);
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!user) {
      getUserAndFetchData();
    }
    if (lead_ID) {
      fetchLeadData();
    }
    getOptionsTasks();

    getOptionsLeads();
    // eslint-disable-next-line
  }, [lead_ID, user, users]);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.users || []); // Set users after the data is fetched
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false); // Set loading to false once fetching is complete
      }
    };

    fetchUsers();
  }, []);
  const handleStatusChange = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    const newIndex = statusOptions.indexOf(newStatus);

    // Update activeStatusIndex to reflect the current and all previous stages as active
    if (newIndex > -1) setActiveStatusIndex(newIndex);
  };

  const stateOptions = useMemo(() => {
    return countries.find((c) => c.name === selectedCountry)?.states || [];
  }, [selectedCountry]);

  const cityOptions = useMemo(
    () => cities[selectedState] || [],
    [selectedState]
  );

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedState(""); // Reset state when country changes
    setSelectedCity(""); // Reset city when country changes
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity(""); // Reset city when state changes
  };

  const handleSave = () => {
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }

    const data = {
      name,
      mobileNo,
      creationDate: new Date().toISOString(),
      requirement,
      leadSource,
      status,
      notes,
      selectedCountry,
      selectedState,
      selectedCity,
      task,
      taskComment,
      taskStatus: "In Progress",
      taskDueDate,
    };

    axios
      .post(
        process.env.REACT_APP_API_ADD_LEAD, // API endpoint
        data, // Data to be sent in the request body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Send token in Authorization header
          },
        }
      )
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Lead Saved Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };

  const handleUpdate = () => {
    const data = {
      lead_ID,
      assignedTo,
      name,
      mobileNo,
      creationDate,
      requirement,
      leadSource,
      status,
      notes,
      selectedCountry,
      selectedState,
      selectedCity,
      task,
      taskComment,
      taskStatus: "In Progress",
      taskDueDate,
    };
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }
    axios
      .post(process.env.REACT_APP_API_UPDATE_LEAD, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Lead Updated Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };

  const leadConvert = async () => {
    try {
      // Start Confetti Effect and set loading to true
      setShowConfetti(true);

      const data = {
        lead_ID,
      };

      const token = localStorage.getItem("authToken");
      if (!token) {
        console.error("No token found in localStorage");
        return; // If no token, stop the function
      }

      const response = await axios.post(
        process.env.REACT_APP_API_ADD_DEAL,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.duplicate) {
        alert("Lead already converted");
        setShowConfetti(false); // Stop Confetti Effect if duplicate
        setLoading(false); // Stop loading
        return;
      }

      // Proceed with the conversion
      setTimeout(() => {
        setShowConfetti(false); // Stop Confetti
        setLoading(false); // Stop loading
        navigate("/deals"); // Navigate to deals page
        window.location.reload(); // Reload the page after navigation
      }, 3000); // 2 seconds delay for confetti effect
    } catch (error) {
      console.log(error);
      setLoading(false); // Stop loading in case of error
    }
  };

  useEffect(() => {
    if (showConfetti) {
      // Disable mouse events on the body
      document.body.style.pointerEvents = "none";
    } else {
      // Re-enable mouse events
      document.body.style.pointerEvents = "auto";
    }

    // Cleanup to ensure no conflicts
    return () => {
      document.body.style.pointerEvents = "auto";
    };
  }, [showConfetti]);

  if (mode === "edit") {
    return (
      <div className="createLead_Section">
        {loading ? (
          <>
            <div className="leadContainer">
              <div className="leadLeft">
                {" "}
                <div className="skelton-container">
                  <div className="skeleton skeleton-item"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                </div>
              </div>
              <div className="leadRight">
                {" "}
                <div className="skelton-container">
                  <div className="skeleton skeleton-item"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                  <div className="skeleton skeleton-item skeleton-item-btn"></div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="leadContainer">
              {/* Left Section */}
              <div className="leadLeft">
                <div className="inputField">
                  <label>Name</label>
                  <div className="inputWrapper">
                    <i className="fas fa-user"></i>
                    <input
                      type="text"
                      placeholder="Enter Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>
                <div className="inputField">
                  <label>Mobile No</label>
                  <div className="inputWrapper">
                    <i className="fas fa-phone-alt"></i>
                    <input
                      type="text"
                      placeholder="Enter Mobile Number"
                      value={mobileNo}
                      onChange={(e) => setMobileNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="inputField">
                  <label>Creation Date</label>
                  <div className="inputWrapper">
                    <i className="fas fa-calendar-alt"></i>
                    <input
                      disabled
                      type="date"
                      value={creationDate}
                      onChange={(e) => setCreationDate(e.target.value)}
                    />
                  </div>
                </div>
                {user && user.role === "admin" ? (
                  <div className="inputField">
                    <label>Assigned to:</label>
                    <div className="inputWrapper">
                      <i className="fas fa-users"></i>
                      <select
                        style={{ cursor: "text" }}
                        value={assignedTo}
                        onChange={(e) => {
                          setAssignedTo(e.target.value);
                          console.log(e.target.value);
                        }}
                      >
                        <option value="selectuser">Select User</option>
                        {users.map((user) => (
                          <option key={user._id} value={user._id}>
                            {user.userName}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : null}

                <div className="inputField">
                  <label>Requirement</label>
                  <div className="inputWrapper">
                    <i className="fas fa-clipboard-list"></i>
                    <select
                      value={requirement}
                      onChange={(e) => setRequirement(e.target.value)} // Update the selected value
                    >
                      <option value="">Select Requirement</option>
                      {leadsOptions.requirementOptions && // Check if requirementOptions exists
                        leadsOptions.requirementOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>Lead Source</label>
                  <div className="inputWrapper">
                    <i className="fas fa-share-alt"></i>
                    <select
                      value={leadSource}
                      onChange={(e) => setLeadSource(e.target.value)}
                    >
                      <option value="">Select Lead Source</option>
                      {leadsOptions.sourceOptions &&
                        leadsOptions.sourceOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>Status</label>
                  <div className="inputWrapper">
                    <i className="fas fa-tasks"></i>
                    <select onChange={handleStatusChange} value={status}>
                      {statusOptions.map((option, idx) => (
                        <option key={idx} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>Notes</label>
                  <div className="inputWrapper">
                    <textarea
                      type="text"
                      placeholder="Enter Notes...."
                      value={notes}
                      onChange={(e) => setNotes(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              {/* Right Section */}
              <div className="leadRight">
                <div className="inputField">
                  <label>Country</label>
                  <div className="inputWrapper">
                    <i className="fas fa-globe"></i>
                    <select
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      {countries.map((country, idx) => (
                        <option key={idx} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>State</label>
                  <div className="inputWrapper">
                    <i className="fas fa-flag"></i>
                    <select value={selectedState} onChange={handleStateChange}>
                      <option value="">Select State</option>
                      {stateOptions.map((state, idx) => (
                        <option key={idx} value={state}>
                          {state}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>City</label>
                  <div className="inputWrapper">
                    <i className="fas fa-city"></i>
                    <select
                      value={selectedCity}
                      onChange={(e) => setSelectedCity(e.target.value)}
                    >
                      <option value="">Select City</option>
                      {cityOptions.map((city, idx) => (
                        <option key={idx} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>Task (Required)</label>
                  <div className="inputWrapper">
                    <i className="fas fa-clipboard-check"></i>
                    <select
                      onChange={(e) => {
                        setTask(e.target.value);
                      }}
                      className="task_new"
                    >
                      <option value="">Add new task</option>
                      {tasksOptions.taskTypesOptions &&
                        tasksOptions.taskTypesOptions.map((option, index) => (
                          <option key={index} value={option}>
                            {option}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                <div className="inputField">
                  <label>Due Date</label>
                  <div className="inputWrapper">
                    <i className="fas fa-calendar-alt"></i>
                    <input
                      type="date"
                      value={taskDueDate}
                      onChange={(e) => setTaskDueDate(e.target.value)}
                    />
                  </div>
                </div>
                <div className="inputField">
                  <label>Task Comment</label>
                  <div className="inputWrapper">
                    <textarea
                      placeholder="Enter Task Comment"
                      value={taskComment}
                      onChange={(e) => setTaskComment(e.target.value)}
                    ></textarea>
                  </div>
                </div>

                <div className="button-container">
                  {/* Update Button */}
                  <button onClick={handleUpdate} className="saveButton">
                    <i
                      className="fa-solid fa-save"
                      style={{ marginRight: "8px" }}
                    ></i>
                    Update
                  </button>
                  <button
                    onClick={() => {
                      if (!mobileNo) {
                        alert("Customer mobile number is missing!");
                        return;
                      }

                      const message = `✨ नमस्ते ${name}, ✨\nहमारी कंपनी, XYZ Industries Pvt. Ltd., जो कि 📍 123, Industrial Area, Dummy City, 456789 पर स्थित है, आपकी सेवा के लिए हमेशा तत्पर है। 🚀\n🔄 आपकी आवश्यकता: "${requirement}" को सफलतापूर्वक अपडेट कर दिया गया है। ✅\n📋 कृपया अधिक जानकारी के लिए तुरंत जांच करें।\nआपकी "${requirement}" संबंधी जानकारी मैं आपको भेज रहा हूँ। ⏳ कृपया थोड़ा इंतज़ार करें। धन्यवाद! 🙏\n💼 सादर, XYZ Industries Pvt. Ltd. 🌟`;

                      const encodedMessage = encodeURIComponent(message);

                      // Force WhatsApp Web
                      const whatsappWebUrl = `https://web.whatsapp.com/send?phone=${mobileNo}&text=${encodedMessage}`;

                      // Open WhatsApp Web
                      window.open(whatsappWebUrl);
                    }}
                    className="saveButton whatsappButton"
                  >
                    <i
                      className="fa-brands fa-whatsapp"
                      style={{ marginRight: "8px" }}
                    ></i>
                    WhatsApp
                  </button>{" "}
                  <button
                    style={{ backgroundColor: "#ce5200" }}
                    className="saveButton cnvrtBTN"
                    onClick={leadConvert}
                  >
                    <i
                      className="fas fa-sync-alt"
                      style={{ marginRight: "8px" }}
                    ></i>
                    {showConfetti ? "Converting..." : "Convert"}
                  </button>{" "}
                  {showConfetti && (
                    <Confetti
                      recycle={false} // Ensure Confetti runs only once
                      numberOfPieces={1500} // Number of confetti pieces
                      gravity={0.12} // Gravity for confetti
                    />
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    marginTop: "5px",
                  }}
                >
                  {isShowToast ? (
                    <Toast
                      message={toastMessage}
                      messageClass={toastMessageClass}
                    />
                  ) : null}
                </div>
              </div>
            </div>
          </>
        )}

        {/* Progression Tracker */}
        <div className="progressionTracker">
          {loading ? null : (
            <>
              <h3>Lead Progression</h3>
              <div className="statusContainer">
                {statusOptions.map((stage, idx) => (
                  <div
                    key={idx}
                    className={`statusItem ${
                      idx <= activeStatusIndex ? "activeStatus" : ""
                    } ${
                      status === "Closed - Converted" &&
                      idx <= activeStatusIndex
                        ? "convertedStatus"
                        : ""
                    }`}
                  >
                    {stage}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="createLead_Section">
          <div className="leadContainer">
            {/* Left Section */}
            <div className="leadLeft">
              <div className="inputField">
                <label>Name</label>
                <div className="inputWrapper">
                  <i className="fas fa-user"></i>
                  <input
                    type="text"
                    placeholder="Enter Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="inputField">
                <label>Mobile No</label>
                <div className="inputWrapper">
                  <i className="fas fa-phone-alt"></i>
                  <input
                    type="text"
                    placeholder="Enter Mobile Number"
                    value={mobileNo}
                    onChange={(e) => setMobileNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="inputField">
                <label>Requirement</label>
                <div className="inputWrapper">
                  <i className="fas fa-clipboard-list"></i>
                  <select
                    value={requirement}
                    onChange={(e) => setRequirement(e.target.value)} // Update the selected value
                  >
                    <option value="">Select Requirement</option>
                    {leadsOptions.requirementOptions && // Check if requirementOptions exists
                      leadsOptions.requirementOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>Lead Source</label>
                <div className="inputWrapper">
                  <i className="fas fa-share-alt"></i>
                  <select
                    value={leadSource}
                    onChange={(e) => setLeadSource(e.target.value)}
                  >
                    <option value="">Select Lead Source</option>
                    {leadsOptions.sourceOptions &&
                      leadsOptions.sourceOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>Status</label>
                <div className="inputWrapper">
                  <i className="fas fa-tasks"></i>
                  <select onChange={handleStatusChange} value={status}>
                    {statusOptions.map((option, idx) => (
                      <option key={idx} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>Notes</label>
                <div className="inputWrapper">
                  <textarea
                    type="text"
                    placeholder="Enter Notes...."
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                  />
                </div>
              </div>
            </div>

            {/* Right Section */}
            <div className="leadRight">
              <div className="inputField">
                <label>Country</label>
                <div className="inputWrapper">
                  <i className="fas fa-globe"></i>
                  <select
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    {countries.map((country, idx) => (
                      <option key={idx} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>State</label>
                <div className="inputWrapper">
                  <i className="fas fa-flag"></i>
                  <select value={selectedState} onChange={handleStateChange}>
                    <option value="">Select State</option>
                    {stateOptions.map((state, idx) => (
                      <option key={idx} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>City</label>
                <div className="inputWrapper">
                  <i className="fas fa-city"></i>
                  <select
                    value={selectedCity}
                    onChange={(e) => setSelectedCity(e.target.value)}
                  >
                    <option value="">Select City</option>
                    {cityOptions.map((city, idx) => (
                      <option key={idx} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>Task (Required)</label>
                <div className="inputWrapper">
                  <i className="fas fa-clipboard-check"></i>
                  <select
                    onChange={(e) => {
                      setTask(e.target.value);
                    }}
                    className="task_new"
                  >
                    <option value="">Add new task</option>
                    {tasksOptions.taskTypesOptions &&
                      tasksOptions.taskTypesOptions.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              <div className="inputField">
                <label>Due Date</label>
                <div className="inputWrapper">
                  <i className="fas fa-calendar-alt"></i>
                  <input
                    type="date"
                    value={taskDueDate}
                    onChange={(e) => setTaskDueDate(e.target.value)}
                  />
                </div>
              </div>
              <div className="inputField">
                <label>Task Comment</label>
                <div className="inputWrapper">
                  <textarea
                    placeholder="Enter Task Comment"
                    value={taskComment}
                    onChange={(e) => setTaskComment(e.target.value)}
                  ></textarea>
                </div>
              </div>
              <button onClick={handleSave} className="saveButton">
                Save
              </button>

              <div
                style={{ width: "100%", textAlign: "center", marginTop: "5px" }}
              >
                {isShowToast ? (
                  <Toast
                    message={toastMessage}
                    messageClass={toastMessageClass}
                  />
                ) : null}
              </div>
            </div>
          </div>

          {/* Progression Tracker */}
          <div className="progressionTracker">
            <h3>Lead Progression</h3>
            <div className="statusContainer">
              {statusOptions.map((stage, idx) => (
                <div
                  key={idx}
                  className={`statusItem ${
                    idx <= activeStatusIndex ? "activeStatus" : ""
                  } ${
                    status === "Converted" && idx <= activeStatusIndex
                      ? "convertedStatus"
                      : ""
                  }`}
                >
                  {stage}
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}
