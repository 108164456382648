import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

import "./acstp.css";
import logo from "../Assets/mainLogo.png";
import axios from "axios";

export default function AccountSetup() {
  const location = useLocation();

  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userMobile, setUserMobile] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    companyName,
    companyAddress,
    companyGSTIN,
    companyContactNo,
    companyEmail,
  } = location.state || {};

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner

    const companyData = {
      companyName,
      companyAddress,
      companyGSTIN,
      companyContactNo,
      companyEmail,
      userName,
      userEmail,
      userMobile, // Sending userMobile instead of userPassword
      userPassword,
    };

    if (userPassword !== confirmPassword) {
      alert("Password Not Matched");
      setLoading(false);
      return;
    }
    // Send data to API using Axios
    axios
      .post(process.env.REACT_APP_API_COMPANY, companyData, {
      })
      .then((response) => {
        console.log("Company added successfully:", response.data);
        // After successful API call, navigate to the next page
        setTimeout(() => {
          navigate("/success-page", {});
          setLoading(false); // Hide spinner after navigation
        }, 2000); // 2-second delay
      })
      .catch((error) => {
        console.error("Error adding company:", error);
        setLoading(false); // Hide spinner on error
      });
  };
  return (
    <section
      className={`SignUpPage_MainSection ${loading ? "loading-active" : ""}`}
    >
      <div className="loginForm">
        <div className="rightDiv_formDiv_SignUpSection">
          <div className="icon_Div_Logo">
            <img src={logo} alt="x360 Marketing Logo Icon" />
            <h1>
              Complete Your <span style={{ color: "#f34100" }}>x360 </span>CRM
              Account Setup
            </h1>
            <p className="paraG">
              Welcome to x360 CRM! Before getting started, please set up your
              account details to personalize your experience and access all
              features.
            </p>
          </div>
          <div className="formDiv">
            <div className="inputFieldDiv">
              <label>User Name</label>
              <input
                onChange={(e) => setUserName(e.target.value)}
                placeholder="Enter your name"
                type="text"
                value={userName}
              />
            </div>

            <div className="inputFieldDiv">
              <label>User Email</label>
              <input
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Enter your email"
                type="email"
                value={userEmail}
              />
            </div>

            <div className="inputFieldDiv">
              <label>User Mobile</label>
              <input
                onChange={(e) => setUserMobile(e.target.value)}
                placeholder="Enter your mobile"
                type="text"
                value={userMobile}
              />
            </div>

            <div className="inputFieldDiv">
              <label>Password*</label>
              <input
                onChange={(e) => {
                  setUserPassword(e.target.value);
                }}
                placeholder="Enter your Password"
                type="password"
                value={userPassword}
              />
            </div>
            <div className="inputFieldDiv">
              <label>Confirm Password*</label>
              <input
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
                placeholder="Confirm your Password"
                type="password"
                value={confirmPassword}
              />
            </div>

            <div className="buttonDiv_form">
              <button onClick={handleSubmit} disabled={loading}>
                Next
              </button>
            </div>

            <span className="newToPlatForm_span">
              Already have an account? <Link to="/">Login Here</Link>
            </span>

            <span className="termsCondition">
              This site is protected by reCAPTCHA and the Google{" "}
              <span style={{ textDecoration: "underline" }}>
                Privacy Policy
              </span>{" "}
              and{" "}
              <span style={{ textDecoration: "underline" }}>
                Terms of Service
              </span>{" "}
              apply.
            </span>
          </div>
        </div>
      </div>

      {/* Loading spinner overlay */}
      {loading && (
        <div className="loadingSpinnerOverlay">
          <i
            className="fas fa-spinner fa-spin"
            style={{ color: "#f34100", fontSize: "2em", marginTop: "10px" }}
          ></i>
        </div>
      )}
    </section>
  );
}
