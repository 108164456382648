import React, { useState } from "react";
import "./user_info.css"; // Updated class file to reflect user info page
import logo from "../Assets/mainLogo.png";
import { useNavigate } from "react-router-dom";

export default function CRT_PSCD() {
  const [passcode, setPasscode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false); // Spinner state

  const navigate = useNavigate(); // Hook to get history for navigation

  const handlePasscodeSubmit = async () => {
    setLoading(true);

    try {
      const response = await fetch(
        process.env.REACT_APP_API_VALIDATE_PASSCODE,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ passcode }),
        }
      );

      const data = await response.json();

      if (data.valid) {
        navigate("/create/companyinformation");
      } else {
        setError("Invalid passcode. Please try again.");
      }
    } catch (error) {
      setError("Error validating passcode. Please try again later.");
    }

    // Ensure loading state is true for at least 1 second
    setTimeout(() => {
      setLoading(false);
    }, 1000); // 1000 milliseconds = 1 second
  };

  return (
    <>
      <section
        className={`UserInfoPage_MainSection ${
          loading ? "loading-active" : ""
        }`}
      >
        <div className="userInfoForm">
          <div className="rightDiv_formDiv_UserInfoSection">
            <div className="icon_Div_Logo">
              <img src={logo} alt="x360 Marketing Logo Icon" />
              <h1>
                Welcome to <span style={{ color: "#f34100" }}>x360 </span>CRM -
                Enter Your Passcode
              </h1>
              <p>
                Please enter your passcode to proceed and complete your account
                setup. <strong> Contact us for passcode.</strong>
              </p>
            </div>
            <div className="formDiv">
              <div className="inputFieldDiv">
                <label htmlFor="passcode">Passcode</label>
                <input
                  onChange={(e) => setPasscode(e.target.value)}
                  placeholder="Enter your passcode"
                  type="text"
                  value={passcode}
                  id="passcode"
                />
              </div>
              {error && <p className="error-message">{error}</p>}

              <div className="buttonDiv_form">
                <button onClick={handlePasscodeSubmit} disabled={loading}>
                  Submit
                </button>
              </div>

              <span
                style={{
                  textAlign: "center",
                  marginTop: "25px",
                  color: "rgb(80,80,80)",
                  fontSize: "12px",
                  fontWeight: "300",
                }}
              >
                This site is protected by reCAPTCHA and the Google{" "}
                <span style={{ textDecoration: "underline" }}>
                  Privacy Policy
                </span>{" "}
                and{" "}
                <span style={{ textDecoration: "underline" }}>
                  Terms of Service
                </span>{" "}
                apply.
              </span>
            </div>
          </div>
        </div>

        {/* Loading spinner overlay */}
        {loading && (
          <div className="loadingSpinnerOverlay">
            <i
              className="fas fa-spinner fa-spin"
              style={{ color: "#f34100", fontSize: "2em", marginTop: "10px" }}
            ></i>
          </div>
        )}
      </section>
    </>
  );
}
