import React, { useEffect, useState } from "react";
import "./tsk-notes.css";
import ownImage from "../Images/ownImg.jpeg";
import axios from "axios";
import Toast from "./Toast";
import { format, formatDistanceToNow } from "date-fns";
import { io } from "socket.io-client";

export default function TaskNotesCom({ taskId }) {
  const [focusedNoteId, setFocusedNoteId] = useState(null);
  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");

  const [users, setUsers] = useState(null);

  // Example dynamic data (this will come from the database)
  const [isFocused, setIsFocused] = useState(false);
  // eslint-disable-next-line
  const [response, setResponse] = useState("");
  const [socket, setSocket] = useState(null); // Store socket instance

  const [messagests, setMessage] = useState();
  const [messages, setMessages] = useState();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    // Socket.IO connection
    const newSocket = io("http://localhost:9000");
    setSocket(newSocket);

    newSocket.on("reply", (data) => {
      console.log(data);

      // Check if `data` is a JSON string, then parse it
      let parsedData;
      if (typeof data === "string") {
        try {
          parsedData = JSON.parse(data); // Parse JSON string to object
        } catch (error) {
          console.error("Error parsing data:", error);
          parsedData = {}; // Default empty object in case of error
        }
      } else {
        parsedData = data; // If already an object, use as-is
      }

      // Safely set response from parsed data
      if (parsedData && parsedData.message) {
        setResponse(parsedData.message);
      } else {
        console.error("Message not found in data:", parsedData);
      }
    });
    // Cleanup on component unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  useEffect(() => {
    const getMessages = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          process.env.REACT_APP_API_GET_MESSAGES,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
            },
          }
        );

        const filteredMessages = response.data.messages.filter(
          (message) => message.taskId === taskId
        );
        setMessages(filteredMessages);
      } catch (error) {
        console.error("Error fetching messages:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (!users) {
      fetchUsers();
    }

    // Fetch messages only if they are not already loaded
    if (!messages) {
      getMessages();
    }

    if (messages && users) {
      setLoading(false);
    }
  }, [messages, users, taskId]); // Depend on 'messages' to re-fetch when it changes
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };

  const handleAddNote = async () => {
    try {
      const newMessage = {
        taskId,
        message: messagests,
      };

      await axios
        .post(process.env.REACT_APP_API_ADD_MESSAGE, newMessage, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
          },
        })
        .then((response) => {
          console.log("Data saved successfully:", response.data);
          setToastMessage("Message Saved Successfully");
          setToastMessageClass("success");
          setIsShowToast(true);
        });
    } catch (error) {
      console.error("Error saving data:", error);
      setToastMessage("Please check entered details and try refreshing page");
      setToastMessageClass("error");
      setIsShowToast(true);
    }
  };

  if (socket) {
    const data = {
      taskId,
      message: messagests,
    };
    socket.emit("message", data); // Send message via existing socket
  }

  return (
    <>
      {" "}
      <div className="addCommentDiv">
        <textarea
          value={messagests}
          onChange={(e) => {
            setMessage(e.target.value);
          }}
          placeholder="Add a note..."
          onFocus={() => setIsFocused(true)}
          //   onBlur={() => setIsFocused(false)}
        />
        {isFocused && (
          <button onClick={handleAddNote} className="commentButton">
            Add Notes
          </button>
        )}
      </div>{" "}
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "-20px",
          marginBottom: "20px",
        }}
      >
        {isShowToast ? (
          <Toast message={toastMessage} messageClass={toastMessageClass} />
        ) : null}
      </div>
      {loading ? (
        <>
          {Array(3) // Creates an array with 3 elements
            .fill(null) // Fills the array with 'null' values
            .map(
              (
                _,
                index // Maps over the array to create skeleton elements
              ) => (
                <div
                  key={index}
                  style={{
                    padding: "0px 25px",
                    paddingBottom: "25px",
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "7px",
                    }}
                  >
                    <div className="skeleton skeleton-profile"></div>
                    <div
                      style={{ width: "140px" }}
                      className="skeleton skeleton-item"
                    ></div>
                  </div>
                  <div className="skeleton skeleton-item"></div>
                </div>
              )
            )}
        </>
      ) : (
        <>
          {messages && messages.length > 0 ? (
            <section className="mn-sec-tsk-nts">
              <div className="mn-dv-tsk-nts">
                {messages
                  .slice() // Create a shallow copy of the array
                  .reverse() // Reverse the array order
                  .map((message, index) => {
                    // Parse message date
                    const messageDate = new Date(message.datentime);

                    // Calculate time difference
                    const timeAgo = formatDistanceToNow(messageDate, {
                      addSuffix: true,
                    });

                    // Format date and time
                    const formattedDate = format(messageDate, "hh:mm a MMM dd");

                    return (
                      <div key={message._id} className="chat-message user-note">
                        <div className="note-header">
                          <div className="profile-section">
                            <img
                              src={ownImage}
                              alt="Profile"
                              className="profile-photo"
                            />
                            <span className="user-name">
                              {getUserName(message.userId)}
                            </span>
                          </div>
                          <span className="note-time">
                            {/* Show "time ago" if within 10 minutes, else show formatted date */}
                            {new Date() - messageDate < 60 * 60 * 1000
                              ? timeAgo
                              : formattedDate}
                          </span>
                        </div>
                        <div className="note-content">
                          <div className="input-container">
                            <input
                              onChange={(e) => {
                                const updatedMessages = [...messages]; // Clone messages array
                                updatedMessages[index].message = e.target.value; // Update specific message
                                setMessages(updatedMessages); // Update the state
                              }}
                              type="text"
                              value={message.message} // Bind to the specific message's value
                              onFocus={() => setFocusedNoteId(message._id)}
                              className="note-input"
                            />
                            {focusedNoteId === message._id && (
                              <button
                                onClick={async () => {
                                  try {
                                    const newMessage = {
                                      messageId: message._id,
                                      taskId,
                                      message: message.message, // Send updated message
                                    };

                                    await axios.post(
                                      process.env.REACT_APP_API_ADD_MESSAGE,
                                      newMessage,
                                      {
                                        headers: {
                                          Authorization: `Bearer ${localStorage.getItem(
                                            "authToken"
                                          )}`,
                                        },
                                      }
                                    );
                                  } catch (error) {
                                    console.error(
                                      "Error updating message:",
                                      error
                                    );
                                  }
                                }}
                                className="update-btn"
                              >
                                Update
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </section>
          ) : null}
        </>
      )}
    </>
  );
}
