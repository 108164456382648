import React, { useEffect, useState } from "react";
import "./leads.css";
import axios from "axios";
import CreateLead from "../Components/CreateLead";
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function Leads() {
  const [loading, setLoading] = useState(true);
  const [crtLD_CLS, setCrt_CLS] = useState("leads_crt_div_hide");
  const [infrLD_CLS, setInfrLD_CLS] = useState("leads_infr_div_hide");
  const [leadID, setLeadID] = useState("leads_infr_div_hide");
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  const [leads, setLeads] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_LEADS
              : process.env.REACT_APP_API_LEADS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          setLeads(response.data.leads);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getUserAndFetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    if (!users) {
      fetchUsers();
    }
    if (!leads || leads.length <= 0) {
      fetchAllData();
    }
    if (!user) {
      getUserAndFetchData();
    }
    if (user && users && leads) {
      setLoading(false);
    }
  }, [user, leads, users]); // `user` triggers fetching only when it changes
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };

  const filteredLeads = leads.filter((lead) =>
    Object.values(lead).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <>
      <section className="mainSection_dash">
        <div className="mainDiv_dash">
          <h3>Leads</h3>
          <h3 style={{ textAlign: "center" }}>
            Let’s convert some leads today! 🚀
          </h3>

          <div className="leads_Container_div">
            <div className="topbar">
              <input
                type="text"
                name="searchleads"
                id="searchleads"
                placeholder="Search from leads"
                className="search-input"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="search-icon">
                <i className="fa-solid fa-magnifying-glass"></i>
              </span>

              <div className="btnDivs">
                <button
                  onClick={() => {
                    setCrt_CLS(
                      crtLD_CLS === "leads_crt_div_hide"
                        ? "leads_crt_div_show"
                        : "leads_crt_div_hide"
                    );
                  }}
                >
                  <i
                    className="fa-solid fa-circle-plus"
                    style={{ color: "#f34100" }}
                  ></i>
                  Create
                </button>
              </div>
            </div>

            <div className="leads_infr_div">
              {/* Create Lead Division */}
              <div className={crtLD_CLS}>
                <div className="insertDiv">
                  <div className="topbar_insrtLD">
                    <h3>Create Lead</h3>

                    <span
                      onClick={() => {
                        setCrt_CLS("leads_crt_div_hide");
                      }}
                      title="Close"
                    >
                      <i className="fa-solid fa-xmark fa-lg"></i>
                    </span>
                  </div>
                  {/* Main Division */}
                  <CreateLead />
                </div>
              </div>
              {/* Create Lead Division */}

              <table>
                <tbody>
                  <tr>
                    <th>Name</th>
                    <th>Mobile</th>
                    <th>Created by</th>
                    <th>Status</th>
                    <th>Requirement</th>
                    <th>City</th>
                    <th>State</th>
                  </tr>

                  {loading ? (
                    <>
                      {Array(7)
                        .fill(null)
                        .map((_, index) => (
                          <tr key={index}>
                            {Array(7)
                              .fill(null)
                              .map((_, colIndex) => (
                                <td key={colIndex}>
                                  <div className="skeleton skeleton-item"></div>
                                </td>
                              ))}
                          </tr>
                        ))}
                    </>
                  ) : (
                    <>
                        {filteredLeads.map((lead) => {
                          return (
                            <React.Fragment key={lead._id}>
                              {leadID === lead._id && (
                                <div className={infrLD_CLS}>
                                  <div className="insertDiv">
                                    <div className="topbar_insrtLD">
                                      <h3>Lead Information</h3>
                                      <span
                                        onClick={() => {
                                          setInfrLD_CLS("leads_infr_div_hide");
                                        }}
                                        title="Close"
                                      >
                                        <i className="fa-solid fa-xmark fa-lg"></i>
                                      </span>
                                    </div>
                                    {/* Main Division */}
                                    <CreateLead
                                      mode="edit"
                                      lead_ID={lead._id}
                                    />
                                  </div>
                                </div>
                              )}
                              {/* Create Lead Division (Clickable Row) */}
                              <tr
                                onClick={() => {
                                  setLeadID(lead._id);
                                  setInfrLD_CLS("leads_infr_div_show");
                                }}
                              >
                                <td>{lead.name}</td>
                                <td>+91 {lead.mobileNo}</td>
                                <td>{getUserName(lead.userId)}</td>
                                <td>{lead.status}</td>
                                <td>{lead.requirement}</td>
                                <td>{lead.selectedCity}</td>
                                <td>{lead.selectedState}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
