import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";

export default function Inbox() {
  const [message, setMessage] = useState("");
  const [response, setResponse] = useState("");
  const [socket, setSocket] = useState(null); // Store socket instance

  useEffect(() => {
    // Socket.IO connection
    const newSocket = io("http://localhost:9000");
    setSocket(newSocket);

    newSocket.on("reply", (data) => {
      console.log(data);

      // Check if `data` is a JSON string, then parse it
      let parsedData;
      if (typeof data === "string") {
        try {
          parsedData = JSON.parse(data); // Parse JSON string to object
        } catch (error) {
          console.error("Error parsing data:", error);
          parsedData = {}; // Default empty object in case of error
        }
      } else {
        parsedData = data; // If already an object, use as-is
      }

      // Safely set response from parsed data
      if (parsedData && parsedData.message) {
        setResponse(parsedData.message);
      } else {
        console.error("Message not found in data:", parsedData);
      }
    });
    // Cleanup on component unmount
    return () => {
      newSocket.disconnect();
    };
  }, []);

  const sendMessage = () => {
    if (socket) {
      const data = {
        userId: "6746d2d417692befdedd283f",
        companyId: "6746d2d417692befdedd283f",
        taskID: "6746d2d417692befdedd283f",
        datentime: "",
        message,
      };
      socket.emit("message", data); // Send message via existing socket
    }
  };

  return (
    <section className="mainSection_dash">
      <div className="mainDiv_dash">
        <div style={{ padding: "20px" }}>
          <h1>Socket.IO Example</h1>
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Type a message"
          />
          <button onClick={sendMessage}>Send</button>

          <h2>
            Server Response: <br /> {response}
          </h2>
        </div>
      </div>
    </section>
  );
}
