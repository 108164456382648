import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./crt_ac.css";
import logo from "../Assets/mainLogo.png";

export default function CreateAc() {
  const [companyName, setCompanyName] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyGSTIN, setCompanyGSTIN] = useState("");
  const [companyContactNo, setCompanyContactNo] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Show spinner
    setTimeout(() => {
      // After 2 seconds, navigate to the next page with form data as state
      navigate("/create/userinformation", {
        state: {
          companyName,
          companyAddress,
          companyGSTIN,
          companyContactNo,
          companyEmail,
        },
      });
      setLoading(false); // Hide spinner after navigation
    }, 2000); // 2-second delay
  };

  return (
    <section className={`SignUpPage_MainSection ${loading ? "loading-active" : ""}`}>
      <div className="loginForm">
        <div className="rightDiv_formDiv_SignUpSection">
          <div className="icon_Div_Logo">
            <img src={logo} alt="x360 Marketing Logo Icon" />
            <h1>
              Welcome to <span style={{ color: "#f34100" }}>x360 </span>CRM - Your Business Journey Starts Here
            </h1>
            <p className="paraG">
              Ready to take your business to the next level? Fill in your company details to begin your journey with our powerful CRM solution.
            </p>
          </div>
          <div className="formDiv">
            <div className="inputFieldDiv">
              <label>Company Name</label>
              <input
                onChange={(e) => setCompanyName(e.target.value)}
                placeholder="Enter your company name"
                type="text"
                value={companyName}
              />
            </div>

            <div className="inputFieldDiv">
              <label>Address</label>
              <input
                onChange={(e) => setCompanyAddress(e.target.value)}
                placeholder="Enter company address"
                type="text"
                value={companyAddress}
              />
            </div>

            <div className="inputFieldDiv">
              <label>GSTIN</label>
              <input
                onChange={(e) => setCompanyGSTIN(e.target.value)}
                placeholder="Enter GSTIN"
                type="text"
                value={companyGSTIN}
              />
            </div>

            <div className="inputFieldDiv">
              <label>Contact No.</label>
              <input
                onChange={(e) => setCompanyContactNo(e.target.value)}
                placeholder="Enter contact number"
                type="text"
                value={companyContactNo}
              />
            </div>

            <div className="inputFieldDiv">
              <label>Email</label>
              <input
                onChange={(e) => setCompanyEmail(e.target.value)}
                placeholder="Enter your email"
                type="email"
                value={companyEmail}
              />
            </div>

            <div className="buttonDiv_form">
              <button onClick={handleSubmit} disabled={loading}>
                Next
              </button>
            </div>

            <span className="newToPlatForm_span">
              Already have an account? <Link to="/">Login Here</Link>
            </span>

            <span className="termsCondition">
              This site is protected by reCAPTCHA and the Google{" "}
              <span style={{ textDecoration: "underline" }}>Privacy Policy</span> and{" "}
              <span style={{ textDecoration: "underline" }}>Terms of Service</span> apply.
            </span>
          </div>
        </div>
      </div>
      
      {/* Loading spinner overlay */}
      {loading && (
        <div className="loadingSpinnerOverlay">
          <i className="fas fa-spinner fa-spin" style={{ color: "#f34100", fontSize: "2em", marginTop: "10px" }}></i>
          </div>
      )}
    </section>
  );
}
