import React, { useEffect, useState } from "react";
import "./contacts.css";
import CreateContact from "../Components/CreateContact";
import axios from "axios";
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function Contacts() {
  const [contact_ID, setContact_ID] = useState("");

  const [isViewContact, setIsViewContact] = useState(false);
  const [mode, setMode] = useState("");
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState(null);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_CONTACTS
              : process.env.REACT_APP_API_CONTACTS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          // console.log(response.data)
          setContacts(response.data.contacts);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const getUserAndFetchData = async () => {
      setLoading(true);
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    if (!users) {
      fetchUsers();
    }
    if (!contacts || contacts.length <= 0) {
      fetchAllData();
    }

    if (!user) {
      getUserAndFetchData();
    }
    if (user && users && contacts) {
      setLoading(false);
    }
  }, [user, contacts, users]); // `user` triggers fetching only when it changes
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };

  const filteredContacts = contacts.filter((deal) =>
    Object.values(deal).some((value) =>
      String(value).toLowerCase().includes(searchQuery.toLowerCase())
    )
  );

  return (
    <>
      <CreateContact
        setIsViewContact={setIsViewContact}
        isViewContact={isViewContact}
        mode={mode}
        contact_ID={contact_ID}
      />
      <section className="mnsec-cntcs-pg">
        <div className="dlsPG_MNSC__container__header">
          <h3>🌍Manage All Your Contacts Effortlessly</h3>
        </div>

        <div className="dlsPG_MNSC__container__topbar">
          <div className="search-deals-inpt">
            <i className="fa-solid fa-magnifying-glass"></i>
            <input
              onChange={(e) => {
                setSearchQuery(e.target.value);
              }}
              type="text"
              name="searchleads"
              id="searchleads"
              placeholder="Search from contacts"
              className="search-input"
            />
          </div>
          <div className="filterIcns_div">
            <button
              onClick={() => {
                setIsViewContact(true);
                setMode("new");
              }}
            >
              <i className="fa-solid fa-circle-plus fa-lg"></i>
              Create contact
            </button>
          </div>
        </div>
        <div className="dlsinfr_dv">
          <table>
            <tbody>
              <tr>
                <th>Name</th>
                <th>Mobile No.</th>
                <th>Created by</th>
                <th>Email</th>
                <th>City</th>
                <th>State</th>
                <th>Country</th>
              </tr>
              {loading ? (
                <>
                  {Array(7)
                    .fill(null)
                    .map((_, index) => (
                      <tr key={index}>
                        {Array(7)
                          .fill(null)
                          .map((_, colIndex) => (
                            <td key={colIndex}>
                              <div className="skeleton skeleton-item"></div>
                            </td>
                          ))}
                      </tr>
                    ))}
                </>
              ) : (
                <>
                  {filteredContacts.map((contact) => {
                    return (
                      <>
                        <tr
                          onClick={() => {
                            setIsViewContact(true);
                            setMode("view");
                            setContact_ID(contact._id);
                          }}
                        >
                          <td>{contact.name}</td>
                          <td>{contact.mobile}</td>
                          <td>{getUserName(contact.userId)}</td>
                          <td>{contact.email}</td>
                          <td>{contact.selectedCity}</td>
                          <td>{contact.selectedState}</td>
                          <td>{contact.selectedCountry}</td>
                        </tr>
                      </>
                    );
                  })}
                </>
              )}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
