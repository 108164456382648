import React, { useState } from "react";
import "./CustomBarChart.css";

const CustomBarChart = () => {
  // Dummy data for the chart
  const [data] = useState([
    { label: "New", value: 10 },
    { label: "Working", value: 50 },
    { label: "Pending", value: 20 },
    { label: "Converted", value: 30 },
    { label: "Not Converted", value: 35 },
  ]);

  // Find the max value to calculate height percentage and scale
  const maxValue = Math.max(...data.map((item) => item.value));
  const step = 10; // Step for Y-axis values

  return (
    <div className="chart-wrapper">
      <div className="chart-container">
        {/* Y-Axis (Values) */}
        <div className="y-axis">
          {Array.from({ length: Math.ceil(maxValue / step) + 1 }, (_, i) => (
            <div className="y-axis-value" key={i}>
              {i * step}
            </div>
          )).reverse()}
        </div>

        {/* Bar Chart */}
        <div className="bar-chart-container">
          <div className="bar-chart">
            {data.map((item, index) => (
              <div className="bar-wrapper" key={index}>
                <div
                  className="bar"
                  style={{
                    height: `${(item.value / maxValue) * 100}%`,
                    backgroundColor: getColor(index), // Assign dynamic color
                  }}
                  title={`${item.label}: ${item.value}`} // Tooltip text
                ></div>
              </div>
            ))}
          </div>

          {/* X-Axis (Labels) */}
          {/* <div className="x-axis">
            {data.map((item, index) => (
              <div className="x-axis-label" key={index}>
                {item.label}
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};

// Function to dynamically assign colors
const getColor = (index) => {
  const colors = [
    "rgba(0, 119, 255, 0.8)", // Secondary brand color (0077FF)
    "rgba(243, 65, 0, 0.8)", // Primary brand color (F34100)
    "rgba(255, 159, 67, 0.8)", // Complementary color (FF9F43)
    "rgba(52, 199, 89, 0.8)", // Fresh green (34C759)
    "rgba(156, 39, 176, 0.8)", // Purple tone (9C27B0)
  ];
  return colors[index % colors.length];
};

export default CustomBarChart;
