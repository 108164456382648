import React, { useEffect, useState } from "react";
import "./useroles.css";
import InputField from "./InputField";
import Toast from "./Toast";
import { fetchCompanyData } from "../Data/CompanyDet"; // Import the function
import { getallUsersData } from "../Data/UserDet"; // Import the function
import axios from "axios";
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function UsersRoles({ isShowUserRoles, setIsShowUserRoles }) {
  const [companyDetails, setCompanyDetails] = useState([]);
  const [users, setUsers] = useState();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Call the function to fetch the company data
    const getCompanyData = async () => {
      const data = await fetchCompanyData();
      if (data) {
        setCompanyDetails(data); // Update the state with the fetched company data
      }
    };
    const getUserData = async () => {
      const data = await getallUsersData();
      if (data) {
        setUsers(data); // Update the state with the fetched company data
      }
    };
    const getUserAndFetchData = async () => {
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    if (!user) {
      getUserAndFetchData();
    }
    getCompanyData();
    getUserData();
  }, [user]);
  const comId = companyDetails._id;

  // Function to handle form submission
  const handleSave = async () => {
    const userData = {
      comId,
      username,
      email,
      role,
      password,
    };

    if (user) {
      if (user.role === "admin") {
        try {
          await axios.post(process.env.REACT_APP_API_ADD_USER, userData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
            },
          });

          setToastMessage("User Saved Successfully");
          setToastMessageClass("success");
          setIsShowToast(true);
          window.location.reload();
        } catch (error) {
          console.error("Error saving user data:", error);
          setToastMessage("Error in saving user data");
          setToastMessageClass("error");
          setIsShowToast(true);
        }
      } else {
        setIsShowToast(true);
        setToastMessage("Action denied: Only admin can add new user.");
        setToastMessageClass("error");
      }
    }
  };

  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");

  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");

  if (!isShowUserRoles) {
    return null; // Return null if the user roles are not fetched
  }

  return (
    <>
      <section className="mn-sc-unr">
        <div className="mn-dv-unr">
          <div className="topbar-unr">
            <h3>Users and Roles</h3>
            <i
              onClick={() => {
                setIsShowUserRoles(false);
              }}
              className="fa-solid fa-xmark"
            ></i>
          </div>
          <div className="users-unr">
            <h3>Users</h3>
            <table>
              <tr>
                <th>Username</th>
                <th>Email</th>
                <th>Role</th>
              </tr>
              {users ? (
                users.map((user) => {
                  return (
                    <tr key={user._id}>
                      <td>{user.userName}</td>
                      <td>{user.userEmail}</td>
                      <td>{user.role}</td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td>No user created</td>
                  <td>NA</td>
                  <td>NA</td>
                </tr>
              )}
            </table>
          </div>

          <div className="add-user-unr">
            <h3>Add User</h3>
            <div className="form-dv-unr">
              {" "}
              <InputField
                heading="User name"
                icon="fa-solid fa-user"
                type="text"
                value={username}
                setValue={setUsername}
                placeholder="Enter user name"
              />
              <InputField
                heading="User email (for login)"
                icon="fa-solid fa-envelope"
                type="text"
                value={email}
                setValue={setEmail}
                placeholder="Enter user email"
              />
              <div className="input-div">
                <label htmlFor="task">User role</label>
                <div className="input-icon-div">
                  <i class="fas fa-users-cog"></i>{" "}
                  <select
                    onChange={(e) => {
                      setRole(e.target.value);
                    }}
                    defaultValue={role}
                    className="task_new"
                  >
                    <option value="select">Select role</option>
                    <option value="admin">Admin</option>
                    <option value="salesman">Salesman</option>
                    <option value="viewer">Viewer</option>
                  </select>
                </div>
              </div>
              <InputField
                heading="Password"
                icon="fa-solid fa-lock"
                type="password"
                value={password}
                setValue={setPassword}
                placeholder="Enter password"
              />
              <InputField
                heading="Confirm password"
                icon="fa-solid fa-lock"
                type="password"
                value={cpassword}
                setValue={setCpassword}
                placeholder="Confirm password"
              />
              <button
                onClick={handleSave}
                style={{ width: "100%", marginTop: "15px" }}
                className="btn-save-deal"
              >
                Save
              </button>
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  marginTop: "5px",
                }}
              >
                {isShowToast ? (
                  <Toast
                    message={toastMessage}
                    messageClass={toastMessageClass}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
