import React, { useState, useMemo, useEffect } from "react";
import InputField from "./InputField";
import "../Components/skelton.css";
import { countries } from "../Data/States";
import { cities } from "../Data/States";
import axios from "axios";
import contactOptions, { fetchContactOptions } from "../Data/Contactsoptions";
import Toast from "./Toast";
import { fetchUserData } from "../Data/UserDet"; // Import the function

// Final Commit

export default function CreateContact({
  isViewContact,
  setIsViewContact,
  mode,
  contact_ID,
}) {
  const [user, setUser] = useState(null);

  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");

  const [contactsOptions, setContactsOptions] = useState(contactOptions); // Initialize with the default object

  // eslint-disable-next-line
  const allContactsURL = process.env.REACT_APP_API_CONTACTS;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getOptions = async () => {
      try {
        setLoading(true);
        const fetchedOptions = await fetchContactOptions(); // Fetch updated options
        if (fetchedOptions) {
          setContactsOptions(fetchedOptions); // Update state with fetched options
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    const fetchContactData = async () => {
      try {
        setLoading(true);

        const token = localStorage.getItem("authToken");

        if (!token) {
          console.error("No token found in localStorage.");
          return;
        }

        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_CONTACTS
              : process.env.REACT_APP_API_CONTACTS;

          const response = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });
          const foundContact = response.data.contacts.find(
            (c) => c._id === contact_ID
          );

          if (foundContact) {
            setName(foundContact.name);
            setMobile(foundContact.mobile);
            setWhatsappMobile(foundContact.whatsappMobile);
            setCompanyName(foundContact.companyName);
            setEmail(foundContact.email);
            setCreationDate(
              new Date(foundContact.creationDate).toISOString().slice(0, 10)
            );

            setEnquiredProduct(foundContact.enquiredProduct);
            setContactType(foundContact.contactType);
            setContactSource(foundContact.contactSource);
            setBirthday(
              new Date(foundContact.birthday).toISOString().slice(0, 10)
            );
            setAnniversary(foundContact.anniversary);
            setSelectedCountry(foundContact.selectedCountry); // Set country
            setSelectedState(foundContact.selectedState); // Set state
            setSelectedCity(foundContact.selectedCity); // Set city
            setNotes(foundContact.notes); // Set notes
          }
        }
      } catch (error) {
        console.error("Error fetching contact data:", error);
      } finally {
        setLoading(false);
      }
    };

    const resetData = () => {
      setName("");
      setMobile("");
      setWhatsappMobile("");
      setCompanyName("");
      setEmail("");
      setCreationDate(new Date()); // Default as today's date
      setEnquiredProduct("");
      setContactType("");
      setContactSource("");
      setBirthday("");
      setAnniversary("");
      setSelectedCountry("India");
      setSelectedState("");
      setSelectedCity("");
      setNotes("");
    };
    const getUserAndFetchData = async () => {
      try {
        setLoading(true);

        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      } finally {
        setLoading(false);
      }
    };
    if (!user) {
      getUserAndFetchData();
    }
    if (contact_ID && mode === "view") {
      fetchContactData();
    } else {
      resetData();
    }
    getOptions();

    // eslint-disable-next-line
  }, [contact_ID, allContactsURL, mode, isViewContact]); // Include isViewContact in dependency array

  // States for initial fields
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [whatsappMobile, setWhatsappMobile] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [creationDate, setCreationDate] = useState(new Date()); // Default as today's date
  const [enquiredProduct, setEnquiredProduct] = useState("");
  const [contactType, setContactType] = useState(""); // e.g., Personal or Business
  const [contactSource, setContactSource] = useState("");
  const [birthday, setBirthday] = useState("");
  const [anniversary, setAnniversary] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("India");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [notes, setNotes] = useState("");
  const stateOptions = useMemo(() => {
    return countries.find((c) => c.name === selectedCountry)?.states || [];
  }, [selectedCountry]);

  const cityOptions = useMemo(
    () => cities[selectedState] || [],
    [selectedState]
  );

  const handleCountryChange = (e) => {
    const country = e.target.value;
    setSelectedCountry(country);
    setSelectedState(""); // Reset state when country changes
    setSelectedCity(""); // Reset city when country changes
  };

  const handleStateChange = (e) => {
    const state = e.target.value;
    setSelectedState(state);
    setSelectedCity(""); // Reset city when state changes
  };

  const handleSave = () => {
    // Save the contact details to the database

    const data = {
      name,
      mobile,
      whatsappMobile,
      companyName,
      email,
      creationDate,
      enquiredProduct,
      contactType,
      contactSource,
      birthday,
      anniversary,
      selectedCountry,
      selectedState,
      selectedCity,
      notes,
    };
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }
    console.log(process.env.REACT_APP_API_ADD_CONTACT);
    axios
      .post(process.env.REACT_APP_API_ADD_CONTACT, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Contact Saved Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };
  const handleUpdate = async () => {
    const data = {
      contact_ID,
      name,
      mobile,
      whatsappMobile,
      companyName,
      email,
      creationDate,
      enquiredProduct,
      contactType,
      contactSource,
      birthday,
      anniversary,
      selectedCountry,
      selectedState,
      selectedCity,
      notes,
    };
    // Ensure token is retrieved correctly from localStorage
    const token = localStorage.getItem("authToken");

    if (!token) {
      console.error("No token found in localStorage.");
      return; // Optionally handle error
    }

    axios
      .post(process.env.REACT_APP_API_UPDATE_CONTACT, data, {
        headers: {
          Authorization: `Bearer ${token}`, // Send token as Authorization header
        },
      })
      .then((response) => {
        console.log("Data saved successfully:", response.data);
        setToastMessage("Deal Updated Successfully");
        setToastMessageClass("success");
        setIsShowToast(true);
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error saving data:", error);
        setToastMessage("Please check entered details and try refreshing page");
        setToastMessageClass("error");
        setIsShowToast(true);
      });
  };

  if (isViewContact) {
    return (
      <>
        <section className="mnsc-crtct mainSectionDeal">
          {" "}
          <div className="mnsc-crtct__cntnr mainDivDeal">
            <div className="topbar-add-dl">
              <h3>
                {mode === "view" ? "Contact Information" : "Create contact"}
              </h3>
              <i
                onClick={() => {
                  setIsViewContact(false);
                }}
                className="fa-solid fa-xmark fa-lg"
              ></i>
            </div>{" "}
            {loading ? (
              <>
                <div className="form-container">
                  <div className="leftDiv ad-dl-dv">
                    <div className="skelton-container">
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                    </div>
                  </div>
                  <div className="rightDiv ad-dl-dv">
                    <div className="skelton-container">
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item"></div>
                      <div className="skeleton skeleton-item skeleton-item-btn"></div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="form-container">
                  <div className="leftDiv ad-dl-dv">
                    <InputField
                      heading="Contact name"
                      icon="fa-solid fa-user"
                      type="text"
                      placeholder="Enter contact name"
                      value={name}
                      setValue={setName}
                    />
                    {/* Mobile Number */}
                    <InputField
                      heading="Mobile"
                      icon="fa-solid fa-phone"
                      type="tel"
                      placeholder="Enter mobile number"
                      value={mobile}
                      setValue={setMobile}
                    />{" "}
                    {/* WhatsApp Mobile */}
                    <InputField
                      heading="WhatsApp Mobile"
                      icon="fa-brands fa-whatsapp"
                      type="tel"
                      placeholder="Enter WhatsApp mobile"
                      value={whatsappMobile}
                      setValue={setWhatsappMobile}
                    />{" "}
                    {/* Company Name */}
                    <InputField
                      heading="Company Name"
                      icon="fa-solid fa-building"
                      type="text"
                      placeholder="Enter company name"
                      value={companyName}
                      setValue={setCompanyName}
                    />{" "}
                    {/* Email Address */}
                    <InputField
                      heading="Email"
                      icon="fa-solid fa-envelope"
                      type="email"
                      placeholder="Enter email"
                      value={email}
                      setValue={setEmail}
                    />
                    {/* Email Address */}
                    {/* Contact Type */}
                    <div className="input-div">
                      <label htmlFor="dealType">Product Enquired</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-tags"></i>
                        <select
                          onChange={(e) => {
                            setEnquiredProduct(e.target.value);
                          }}
                          value={enquiredProduct}
                          className="input-field"
                        >
                          <option value="">Select product</option>
                          {contactsOptions.contactRequirements &&
                            contactsOptions.contactRequirements.map(
                              (option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>
                    {/* Contact Type */}
                    <div className="input-div">
                      <label htmlFor="dealType">Contact Type</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-tags"></i>
                        <select
                          onChange={(e) => {
                            setContactType(e.target.value);
                          }}
                          value={contactType}
                          className="input-field"
                        >
                          <option value="">Select contact type</option>
                          {contactsOptions.contactType &&
                            contactsOptions.contactType.map((option, index) => (
                              <option key={index} value={option}>
                                {option}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    {/* Contact Source */}
                    <div className="input-div">
                      <label htmlFor="dealType">Contact source</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-tags"></i>
                        <select
                          onChange={(e) => {
                            setContactSource(e.target.value);
                          }}
                          value={contactSource}
                          className="input-field"
                        >
                          <option value="">Select contact source</option>
                          {contactsOptions.contactSource &&
                            contactsOptions.contactSource.map(
                              (option, index) => (
                                <option key={index} value={option}>
                                  {option}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="rightDiv ad-dl-dv">
                    <InputField
                      heading="Creation date"
                      icon="fa-solid fa-envelope"
                      type="date"
                      placeholder="Enter date"
                      value={creationDate}
                      setValue={setCreationDate}
                      disabled={true}
                    />
                    {/* Birthday */}
                    <InputField
                      heading="Birthday"
                      icon="fa-solid fa-birthday-cake"
                      type="date"
                      value={birthday}
                      setValue={setBirthday}
                    />
                    <div className="input-div">
                      <label htmlFor="country">Country</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-globe"></i>
                        <select
                          className="input-field"
                          value={selectedCountry}
                          onChange={handleCountryChange}
                        >
                          {countries.map((country, idx) => (
                            <option key={idx} value={country.name}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="state">State</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-map"></i>
                        <select
                          className="input-field"
                          value={selectedState}
                          onChange={handleStateChange}
                        >
                          <option value="">Select State</option>
                          {stateOptions.map((state, idx) => (
                            <option key={idx} value={state}>
                              {state}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="city">City</label>
                      <div className="input-icon-div">
                        <i className="fa-solid fa-city"></i>
                        <select
                          className="input-field"
                          value={selectedCity}
                          onChange={(e) => setSelectedCity(e.target.value)}
                        >
                          <option value="">Select City</option>
                          {cityOptions.map((city, idx) => (
                            <option key={idx} value={city}>
                              {city}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="input-div">
                      <label htmlFor="notes">Notes</label>
                      <textarea
                        value={notes}
                        onChange={(e) => {
                          setNotes(e.target.value);
                        }}
                        id="notes"
                        placeholder="Enter notes"
                        className="textarea-field"
                      ></textarea>
                    </div>{" "}
                    {mode === "new" ? (
                      <button onClick={handleSave} className="btn-save-deal">
                        Save
                      </button>
                    ) : mode === "view" ? (
                      <button onClick={handleUpdate} className="btn-save-deal">
                        Update
                      </button>
                    ) : null}
                    <div
                      style={{
                        width: "100%",
                        textAlign: "center",
                        marginTop: "5px",
                      }}
                    >
                      {isShowToast ? (
                        <Toast
                          message={toastMessage}
                          messageClass={toastMessageClass}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </section>
      </>
    );
  }
}
