// DealsOptions.js

let dealOptions = {
    typeOptions: "",
    stagesOptions: "",
    sourceOptions: "",
    requirementOptions: "",
  };
  
  // Function to fetch deal options and update the `dealOptions` object
  export const fetchDealOptions = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_API_OPTIONS_DEALS, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Include token if required
        },
      });
  
      if (!response.ok) {
        throw new Error("Failed to fetch deal options");
      }
  
      const data = await response.json();
      dealOptions = data.data; // Update the `dealOptions` object with the fetched data
      return dealOptions; // Return updated options for immediate use
    } catch (error) {
      console.error("Error fetching deal options:", error);
      dealOptions = null; // If error occurs, set `dealOptions` to null
      return null; // Return null to indicate failure
    }
  };
  
  // Export the dealOptions object for direct use
  export default dealOptions;
  