import React, { useEffect, useState } from "react";
import "./dashboard.css";
import axios from "axios";
import Taskview from "../Components/Taskview";
import CustomBarChart from "../Functions/LeadsbyStatus";
import CustomPieChart from "../Functions/PieChartCstm";
import FunnelChart from "../Functions/FunnelChart";
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function Dashboard() {
  const [currentDate, setCurrentDate] = useState("");
  const [greeting, setGreeting] = useState("");
  const [dueTasks, setDueTasks] = useState([]);
  const [leads, setLeads] = useState([]);
  const [deals, setDeals] = useState([]);
  const [user, setUser] = useState(null);
  const [users, setUsers] = useState(null);

  const [selectedTaskId, setSelectedTaskId] = useState(null); // New state to track selected task  const [taskCls, setTskCls] = useState("taskView_div_hide");
  const [taskCls, setTskCls] = useState("taskView_div_hide");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Set up date and greeting
    const today = new Date();
    const formattedDate = today.toLocaleDateString("en-US", {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "numeric",
    });
    setCurrentDate(formattedDate);

    const currentHour = today.getHours();
    if (currentHour < 12) {
      setGreeting("Good morning");
    } else if (currentHour < 18) {
      setGreeting("Good afternoon");
    } else {
      setGreeting("Good evening");
    }

    // Fetch tasks and leads
    const fetchData = async () => {
      const token = localStorage.getItem("authToken");

      try {
        if (user) {
          const url =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_LEADS
              : process.env.REACT_APP_API_LEADS;

          const response_leads = await axios.get(url, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });

          const url1 =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_DEALS
              : process.env.REACT_APP_API_DEALS;

          const response2 = await axios.get(url1, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });
          const url2 =
            user.role === "admin"
              ? process.env.REACT_APP_API_TOTAL_TASKS
              : process.env.REACT_APP_API_TASKS;

          const response = await axios.get(url2, {
            headers: {
              Authorization: `Bearer ${token}`,
              fetchLeads: "fetchLeads",
            },
          });
          // Filter tasks that are due today or earlier
          const todayDate = new Date();
          todayDate.setHours(0, 0, 0, 0); // Reset time part for today
          const tasksDueTodayOrEarlier = response.data.tasks.filter((task) => {
            const taskDueDate = new Date(task.taskDueDate);
            taskDueDate.setHours(0, 0, 0, 0); // Reset time part of task due date
            return taskDueDate <= todayDate;
          });
          setDueTasks(tasksDueTodayOrEarlier); // Set filtered tasks
          setLeads(response_leads.data.leads); // Set leads
          setDeals(response2.data.deals);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getUserAndFetchData = async () => {
      try {
        const data = await fetchUserData();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUsers(response.data.users); // Assuming response includes a list of users
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    if (!users) {
      fetchUsers();
    }
    if (
      !leads ||
      leads.length <= 0 ||
      !deals ||
      deals.length <= 0 ||
      !dueTasks ||
      dueTasks.length <= 0
    ) {
      fetchData();
    }

    if (!user) {
      getUserAndFetchData();
    }
    if (user && users && leads && deals && dueTasks) {
      setLoading(false);
    }
  }, [user, deals, dueTasks, leads, users]);
  const getUserName = (userId) => {
    const user = users.find((user) => user._id === userId);
    return user ? user.userName : "Unknown"; // Fallback if user is not found
  };
  // Function to format the due date to a readable string
  const formatDueDate = (dueDate) => {
    const today = new Date();
    const taskDueDate = new Date(dueDate);

    if (taskDueDate.toDateString() === today.toDateString()) {
      return "Today";
    }

    const tomorrow = new Date(today);
    tomorrow.setDate(today.getDate() + 1);
    if (taskDueDate.toDateString() === tomorrow.toDateString()) {
      return "Tomorrow";
    }

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    if (taskDueDate.toDateString() === yesterday.toDateString()) {
      return "Yesterday";
    }

    const options = { year: "numeric", month: "short", day: "numeric" };
    return taskDueDate.toLocaleDateString("en-US", options);
  };

  // Function to close the Taskview
  const handleCloseTaskView = () => {
    setTskCls("taskView_div_hide");
    setSelectedTaskId(null); // Set selectedTaskId to null to hide Taskview
  };

  return (
    <>
      {" "}
      <div className={taskCls}>
        <Taskview mode="new" onClose={handleCloseTaskView} />
      </div>
      <section className="mainSection_dash">
        <div className="mainDiv_dash">
          <h3>Home</h3>

          <span>{currentDate}</span>
          <h2>
            {greeting}, {user ? user.userName : "loading..."}
          </h2>

          <div className="todaysDueTasks_Div">
            <div className="todaysDueTasks_Header">
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {" "}
                <h4>Today's Due Task</h4>
                <button
                  onClick={() => {
                    setTskCls("taskView_div_show");
                  }}
                >
                  <i class="fa-solid fa-plus"></i> Add Task
                </button>
              </div>
              <div className="dvd"></div>
            </div>
            <table>
              <tbody>
                <tr>
                  <th style={{ width: "25px" }}>Status</th>
                  <th>Task</th>
                  <th>Assigned To (Assignee) </th>
                  <th>Under (Lead/Deal)</th>
                  <th>Mobile</th>
                  <th>Comment</th>
                  <th>Due date</th>
                </tr>

                {loading ? (
                  <>
                    {Array(5)
                      .fill(null)
                      .map((_, index) => (
                        <tr key={index}>
                          {Array(7)
                            .fill(null)
                            .map((_, colIndex) => (
                              <td key={colIndex}>
                                <div className="skeleton skeleton-item"></div>
                              </td>
                            ))}
                        </tr>
                      ))}
                  </>
                ) : (
                  <>
                    {dueTasks
                      .filter((task) => {
                        const taskDate = new Date(task.taskDueDate);
                        const today = new Date();

                        // Check if the task is from today or earlier
                        const isToday =
                          taskDate.getFullYear() === today.getFullYear() &&
                          taskDate.getMonth() === today.getMonth() &&
                          taskDate.getDate() === today.getDate();

                        // Filter logic
                        if (isToday) {
                          return true; // Include today's tasks irrespective of status
                        } else {
                          return task.taskStatus !== "Completed"; // Include earlier tasks only if not completed
                        }
                      })
                      .sort(
                        (a, b) =>
                          new Date(a.taskDueDate) - new Date(b.taskDueDate)
                      ) // Sort by due date
                      .map((task, index) => {
                        let lead;
                        lead = leads.find((lead) => task.lead_ID === lead._id);
                        if (lead === null || lead === undefined) {
                          lead = deals.find(
                            (lead) => task.lead_ID === lead._id
                          );
                        }

                        return (
                          <React.Fragment key={index}>
                            <tr
                              style={{
                                color:
                                  task.taskStatus === "Completed"
                                    ? "rgb(120,120,120)"
                                    : task.taskStatus === "Pending",
                              }}
                              onClick={() => {
                                setSelectedTaskId(task._id); // Set the selected task ID on click
                              }}
                            >
                              <td>
                                {task.taskStatus === "Completed" ? (
                                  <i
                                    className="fa-solid fa-circle-check fa-lg"
                                    style={{ color: "#00c220" }} // Green color for "Completed"
                                  ></i>
                                ) : task.taskStatus === "Pending" ? (
                                  <i
                                    className="fa-regular fa-circle-check fa-lg"
                                    style={{ color: "red" }} // Red color for "Pending"
                                  ></i>
                                ) : (
                                  <i
                                    className="fa-regular fa-circle-check fa-lg"
                                    style={{ color: "#ccc" }} // Default color for other statuses
                                  ></i>
                                )}
                              </td>
                              <td>{task.task}</td>
                              <td>{getUserName(task.userId)}</td>
                              <td>
                                {lead
                                  ? lead.name
                                    ? lead.name
                                    : lead.dealName
                                  : "Loading..."}
                              </td>
                              <td>
                                {lead
                                  ? lead.mobileNo
                                    ? lead.mobileNo
                                    : "No Contact"
                                  : "Loading..."}
                              </td>
                              <td>{task.taskComment}</td>
                              <td>{formatDueDate(task.taskDueDate)}</td>
                            </tr>

                            {/* Only show Taskview if a task is selected */}
                            {selectedTaskId === task._id && (
                              <div className="taskView_div_show">
                                <Taskview
                                  mode="view"
                                  task={task._id}
                                  onClose={handleCloseTaskView}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        );
                      })}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <h3
            style={{
              width: "100%",
              textAlign: "center",
              margin: "20px",
              marginTop: "40px",
              padding: "0px",
            }}
          >
            Beta Dashboard (This is a preview with dummy data. To unlock full
            access, please contact us.)
          </h3>

          <div
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "center",
              gap: "25px",
            }}
          >
            {" "}
            <div className="leadsByStatus">
              {/* <p>Leads by Status</p> */}
              <CustomBarChart />
            </div>
            <div className="leadsBySource">
              {/* <p>Leads by Source</p> */}
              <CustomPieChart />
            </div>
          </div>
          <div className="dealsByStage">
            {/* <p>Deals by Stage</p> */}
            <FunnelChart />
          </div>
        </div>
      </section>
    </>
  );
}
