export const fetchCompanyData = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_API_GET_COMPANY, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch company data");
    }

    const data = await response.json();
    return data.company; // Return the company data to be used in the component
  } catch (error) {
    console.log("Error", error);
    return null; // Return null or handle the error appropriately
  }
};

// Com Det Final Commit