import React, { useEffect, useState } from "react";
import "./crmsettings.css";
import InputField from "./InputField";
import axios from "axios";
import { fetchCompanyData } from "../Data/CompanyDet"; // Import the function
import { fetchUserData } from "../Data/UserDet"; // Import the function

export default function CrmSettingsComp({ isShowSettings, setIsShowSettings }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const [companyDetails, setCompanyDetails] = useState([]);
  // eslint-disable-next-line
  const [error, setError] = useState("");
  // State variables to store data
  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    companyEmail: "",
    gstin: "",
    contact: "",
    com_address: "",
  });

  const [userInfo, setUserInfo] = useState({
    fullName: "",
    email: "",
    contact: "",
    department: "Sales",
    address: "User address",
  });

  const [leadOptions, setLeadOptions] = useState({
    statusOptions: "",
    sourceOptions: "",
    requirementOptions: "",
  });

  const [dealOptions, setDealOptions] = useState({
    typeOptions: "",
    stagesOptions: "",
    sourceOptions: "",
    requirementOptions: "",
  });
  const [contactOptions, setContactOptions] = useState({
    contactRequirements: "",
    contactType: "",
    contactSource: "",
  });
  const [taskOptions, setTaskOptions] = useState({
    taskTypesOptions: "", // Field to store textarea value
  });

  // Fetch data using useEffect
  useEffect(() => {
    const getUser = async () => {
      setLoading(true);
      try {
        const data = await fetchUserData();

        if (data) {
          setUserInfo({
            fullName: data.userName, // Mapping companyName from data
            email: data.userEmail || "", // Default empty if no email
            contact: data.userMobile || "", // Default empty if no gstin
          });
          setUser(data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    // Fetch options data
    const fetchOptionsData = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");

        const leadResponse = await axios.get(
          process.env.REACT_APP_API_OPTIONS_LEADS,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (leadResponse.data) {
          setLeadOptions({
            statusOptions: leadResponse.data.data.statusOptions.join("\n"), // Join array with new line
            sourceOptions: leadResponse.data.data.sourceOptions.join("\n"), // Join array with new line
            requirementOptions:
              leadResponse.data.data.requirementOptions.join("\n"), // Join array with new line
          });
        } else {
          setLeadOptions({
            statusOptions: "",
            sourceOptions: "",
            requirementOptions: "",
          });
        }

        const dealResponse = await axios.get(
          process.env.REACT_APP_API_OPTIONS_DEALS,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Token here
            },
          }
        );

        if (dealResponse.data) {
          setDealOptions({
            typeOptions: dealResponse.data.data.typeOptions.join("\n"),
            stagesOptions: dealResponse.data.data.stagesOptions.join("\n"),
            sourceOptions: dealResponse.data.data.sourceOptions.join("\n"),
            requirementOptions:
              dealResponse.data.data.requirementOptions.join("\n"),
          });
        } else {
          setDealOptions({
            typeOptions: "",
            stagesOptions: "",
            sourceOptions: "",
            requirementOptions: "",
          });
        }

        // Fetch Contact options
        const contactResponse = await axios.get(
          process.env.REACT_APP_API_OPTIONS_CONTACTS,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
            },
          }
        );
        if (contactResponse.data) {
          setContactOptions({
            contactRequirements:
              contactResponse.data.data.contactRequirements.join("\n"),
            contactType: contactResponse.data.data.contactType.join("\n"),
            contactSource: contactResponse.data.data.contactSource.join("\n"),
          });
        } else {
          setContactOptions({
            contactRequirements: "",
            contactType: "",
            contactSource: "",
          });
        }

        // Fetch Task options
        const taskResponse = await axios.get(
          process.env.REACT_APP_API_OPTIONS_TASKS,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
            },
          }
        );
        // console.log(localStorage.getItem("authToken"))
        if (taskResponse.data) {
          setTaskOptions({
            taskTypesOptions:
              taskResponse.data.data.taskTypesOptions.join("\n"),
          });
        } else {
          setTaskOptions({ taskTypesOptions: "" });
        }
      } catch (error) {
        console.error("Error fetching options data:", error);
        // Fallback to default state if there is an error
        setLeadOptions({
          statusOptions: "",
          sourceOptions: "",
          requirementOptions: "",
        });
        setDealOptions({
          typeOptions: "",
          stagesOptions: "",
          sourceOptions: "",
          requirementOptions: "",
        });
        setContactOptions({
          contactRequirements: "",
          contactType: "",
          contactSource: "",
        });
        setTaskOptions({ taskTypesOptions: "" });
      } finally {
        setLoading(false);
      }
    };
    const getCompanyData = async () => {
      setLoading(true);
      try {
        const data = await fetchCompanyData();
        if (data) {
          setCompanyInfo({
            companyName: data.companyName, // Mapping companyName from data
            companyEmail: data.companyEmail || "", // Default empty if no email
            gstin: data.companyGSTIN || "", // Default empty if no gstin
            contact: data.companyContactNo || "", // Default empty if no contact
            com_address: data.companyAddress || "", // Default empty if no com_address
          });

          setCompanyDetails(data); // Update the state with the fetched company data
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    // Fetch both user and company data when the component mounts
    getUser();
    getCompanyData();
    fetchOptionsData();
  }, []); // Empty dependency array to run only once when the component mounts

  const handleChange = (e, setStateFunc) => {
    const { name, value } = e.target;
    setStateFunc((prevState) => ({
      ...prevState,
      [name]: value, // Dynamically update the state based on name
    }));
  };

  const updateUser = async () => {
    try {
      // Prepare the API URL from the environment variable
      const apiUrl = process.env.REACT_APP_API_UPDATE_USER;

      // Define the payload (request body)
      const payload = {
        userId: user._id, // ID of the user to update
        userName: userInfo.fullName, // New user name
        userMobile: userInfo.contact, // New user mobile
      };

      // Send the POST request to the API
      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
        },
      });

      // Handle the successful response
      if (response.status === 200) {
        console.log("User updated successfully:", response.data);
        window.location.reload();
        return response.data; // Return the data if needed elsewhere
      }
    } catch (error) {
      // Handle errors during the API call
      console.error(
        "Failed to update user:",
        error.response?.data || error.message
      );
      alert("An error occurred while updating the user.");
    }
  };
  const updateCompany = async () => {
    try {
      // Prepare the API URL from the environment variable
      const apiUrl = process.env.REACT_APP_API_UPDATE_COMPANY;

      // Define the payload (request body)
      const payload = {
        companyID: companyDetails._id, // ID of the company to update
        companyName: companyInfo.companyName, // New company name
        companyAddress: companyInfo.com_address, // New company address
        companyGSTIN: companyInfo.gstin, // New company GSTIN
        companyContactNo: companyInfo.contact, // New company contact number
        companyEmail: companyInfo.companyEmail, // New company email
      };

      // Send the POST request to the API
      const response = await axios.post(apiUrl, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
        },
      });

      // Handle the successful response
      if (response.status === 200) {
        console.log("Company updated successfully:", response.data);
        window.location.reload();
        return response.data; // Return the data if needed elsewhere
      }
    } catch (error) {
      // Handle errors during the API call
      console.error(
        "Failed to update company:",
        error.response?.data || error.message
      );
      alert("An error occurred while updating the company.");
    }
  };

  // Handle Status Save
  const handleLeadsOptionsSave = async (optionsData) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_OPTIONS_LEADS,
        optionsData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
          },
        }
      );

      console.log(response.data);
      alert("Saved");
    } catch (error) {
      console.error("Error while saving options:", error);
    }
  };
  // Handle Status Save
  const handleDealOptionsSave = async (optionsData) => {
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_OPTIONS_DEALS, // Using the environment variable here
        optionsData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
          },
        }
      );
      console.log("Deal options saved successfully:", response.data);
      alert("Saved");
    } catch (error) {
      console.error("Error while saving deal options:", error);
    }
  };
  // Handle Contacts Save
  const handleContactsOptionsSave = async (optionsData) => {
    console.log(optionsData);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_OPTIONS_CONTACTS, // Use the environment variable for the contacts API
        optionsData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
          },
        }
      );

      console.log("Contacts options saved successfully:", response.data);
      alert("Saved");
    } catch (error) {
      console.error("Error while saving contacts options:", error);
    }
  };
  // Handle Tasks Save
  const handleTasksOptionsSave = async (optionsData) => {
    console.log(optionsData);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_OPTIONS_TASKS, // Use the environment variable for the tasks API
        optionsData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Assuming the token is stored in localStorage
          },
        }
      );

      console.log("Tasks options saved successfully:", response.data);
      alert("Saved");
    } catch (error) {
      console.error("Error while saving tasks options:", error);
    }
  };

  const handleSave = (optionsType) => {
    let optionsData;

    switch (optionsType) {
      case "user":
        updateUser();

        break;
      case "company":
        updateCompany();
        break;

      case "Leads":
        optionsData = {
          statusOptions: leadOptions.statusOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
          sourceOptions: leadOptions.sourceOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
          requirementOptions: leadOptions.requirementOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
        };

        handleLeadsOptionsSave(optionsData);
        break;
      case "Deals":
        optionsData = {
          typeOptions: dealOptions.typeOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
          stagesOptions: dealOptions.stagesOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
          sourceOptions: dealOptions.sourceOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
          requirementOptions: dealOptions.requirementOptions
            .split("\n")
            .filter((option) => option.trim() !== ""),
        };

        handleDealOptionsSave(optionsData);
        break;
      case "Contacts":
        optionsData = {
          contactRequirements: contactOptions.contactRequirements
            .split("\n")
            .filter((option) => option.trim() !== ""), // Filter empty lines
          contactType: contactOptions.contactType
            .split("\n")
            .filter((option) => option.trim() !== ""), // Filter empty lines
          contactSource: contactOptions.contactSource
            .split("\n")
            .filter((option) => option.trim() !== ""), // Filter empty lines
        };
        handleContactsOptionsSave(optionsData);
        console.log("Contact Options:", optionsData);
        break;
      case "Tasks":
        optionsData = {
          taskTypesOptions: taskOptions.taskTypesOptions
            .split("\n")
            .filter((option) => option.trim() !== ""), // Filter empty lines
        };
        handleTasksOptionsSave(optionsData);
        console.log("Tasks Options:", optionsData);
        break;
      default:
        console.log("No valid options type provided");
    }
  };

  // State to track active tab
  const [activeTab, setActiveTab] = useState("General");

  if (loading) {
    return (
      <div>
        <div className="skelton-container">
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
          <div className="skeleton skeleton-item"></div>
        </div>
      </div>
    );
  }

  const renderContent = () => {
    switch (activeTab) {
      case "General":
        return (
          <div className="tab-content">
            <h3>About Application</h3>
            <ul>
              <li>
                <span>App Version:</span> 1.2.0 (Beta)
              </li>
              <li>
                <span>System Compatibility:</span> Windows 10/10+ (x64), macOS
              </li>
              <li>
                <span>CPU Usage:</span> Approx. 0.06% for this process, 0.18%
                total
              </li>
              <li>
                <span>Memory Usage:</span> 291 MB used by this process, 1 GB
                used by the app
              </li>
              <li>
                <span>Developer:</span> x360 marketing
              </li>
              <li>
                <span>Support Contact:</span> support@x360crm.com
              </li>
            </ul>

            <div className="cmp-infr-dv">
              <h3>Company Information</h3>
              <div className="mn-dv">
                <div className="cmp-inf-dvs-inr">
                  <InputField
                    heading="Company"
                    type="text"
                    placeholder="Enter company name"
                    icon="fas fa-city"
                    name="companyName"
                    value={companyInfo.companyName}
                    setValue={(value) =>
                      setCompanyInfo((prevState) => ({
                        ...prevState,
                        companyName: value,
                      }))
                    }
                  />
                  <InputField
                    heading="Email"
                    type="text"
                    placeholder="Enter company email"
                    icon="fas fa-envelope"
                    name="companyEmail"
                    value={companyInfo.companyEmail}
                    setValue={(value) =>
                      setCompanyInfo((prevState) => ({
                        ...prevState,
                        companyEmail: value,
                      }))
                    }
                  />
                  <InputField
                    heading="GSTIN"
                    type="text"
                    placeholder="Enter company GSTIN"
                    icon="fas fa-id-card"
                    name="gstin"
                    value={companyInfo.gstin}
                    setValue={(value) =>
                      setCompanyInfo((prevState) => ({
                        ...prevState,
                        gstin: value,
                      }))
                    } // Passing setValue function directly as prop
                  />
                </div>
                <div className="cmp-inf-dvs-inr">
                  <InputField
                    heading="Contact"
                    type="text"
                    placeholder="Enter company contact"
                    icon="fas fa-phone"
                    name="contact"
                    value={companyInfo.contact}
                    setValue={(value) =>
                      setCompanyInfo((prevState) => ({
                        ...prevState,
                        contact: value,
                      }))
                    }
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "11px 0px",
                    }}
                  >
                    <div className="inputField">
                      <label>Address</label>
                      <div className="inputWrapper">
                        <textarea
                          name="com_address"
                          placeholder="Address"
                          value={companyInfo.com_address}
                          onChange={(e) => handleChange(e, setCompanyInfo)}
                        />
                      </div>
                    </div>

                    <button
                      className="btn-save-deal"
                      onClick={() => handleSave("company")}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div className="cmp-infr-dv">
              <h3>User Information</h3>
              <div className="mn-dv">
                <div className="cmp-inf-dvs-inr">
                  <InputField
                    heading="Full Name"
                    type="text"
                    placeholder="Enter your full name"
                    icon="fas fa-user"
                    name="fullName"
                    value={userInfo.fullName}
                    setValue={(value) =>
                      setUserInfo((prevState) => ({
                        ...prevState,
                        fullName: value,
                      }))
                    }
                  />
                  <InputField
                    heading="Email"
                    type="text"
                    placeholder="Enter your email"
                    icon="fas fa-envelope"
                    name="email"
                    disabled={true}
                    value={userInfo.email}
                    setValue={(value) =>
                      setUserInfo((prevState) => ({
                        ...prevState,
                        email: value,
                      }))
                    }
                    readOnly
                  />
                  <InputField
                    heading="Contact"
                    type="text"
                    placeholder="Enter your contact number"
                    icon="fas fa-phone"
                    name="contact"
                    value={userInfo.contact}
                    setValue={(value) =>
                      setUserInfo((prevState) => ({
                        ...prevState,
                        contact: value,
                      }))
                    }
                  />
                </div>
                <div className="cmp-inf-dvs-inr">
                  <InputField
                    heading="Department"
                    type="text"
                    disabled={true}
                    placeholder="Enter department (e.g., Sales)"
                    icon="fas fa-users"
                    name="department"
                    value={userInfo.department}
                    setValue={(value) =>
                      setUserInfo((prevState) => ({
                        ...prevState,
                        department: value,
                      }))
                    }
                  />
                  <div className="inputField">
                    <label>Address</label>
                    <div className="inputWrapper">
                      <textarea
                        name="address"
                        disabled={true}
                        placeholder="Enter your address"
                        value={userInfo.address}
                        onChange={(e) => handleChange(e, setUserInfo)}
                      />
                    </div>
                  </div>

                  <button
                    style={{
                      width: "100%",
                    }}
                    className="btn-save-deal"
                    onClick={() => handleSave("user")}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      case "Leads":
        return (
          <div className="tab-content">
            <h3>Customize lead options</h3>
            <div className="txt-area-dv">
              <label htmlFor="statusOptions">Status options</label>
              <textarea
                name="statusOptions"
                value={leadOptions.statusOptions}
                onChange={(e) => handleChange(e, setLeadOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="sourceOptions">Source options</label>
              <textarea
                name="sourceOptions"
                value={leadOptions.sourceOptions}
                onChange={(e) => handleChange(e, setLeadOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="requirementOptions">Requirement options</label>
              <textarea
                name="requirementOptions"
                value={leadOptions.requirementOptions}
                onChange={(e) => handleChange(e, setLeadOptions)}
              />
            </div>
            <button
              style={{
                width: "100%",
                marginTop: "25px",
              }}
              className="btn-save-deal"
              onClick={() => handleSave("Leads")}
            >
              Save
            </button>
          </div>
        );
      case "Deals":
        return (
          <div className="tab-content">
            <h3>Customize deal options</h3>
            <div className="txt-area-dv">
              <label htmlFor="typeOptions">Type options</label>
              <textarea
                name="typeOptions"
                value={dealOptions.typeOptions}
                onChange={(e) => handleChange(e, setDealOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="stagesOptions">Stages options</label>
              <textarea
                name="stagesOptions"
                value={dealOptions.stagesOptions}
                onChange={(e) => handleChange(e, setDealOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="sourceOptions">Source options</label>
              <textarea
                name="sourceOptions"
                value={dealOptions.sourceOptions}
                onChange={(e) => handleChange(e, setDealOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="requirementOptions">Requirement options</label>
              <textarea
                name="requirementOptions"
                value={dealOptions.requirementOptions}
                onChange={(e) => handleChange(e, setDealOptions)}
              />
            </div>
            <button
              style={{
                width: "100%",
                marginTop: "25px",
              }}
              className="btn-save-deal"
              onClick={() => handleSave("Deals")}
            >
              Save
            </button>
          </div>
        );
      case "Contacts":
        return (
          <div className="tab-content">
            <h3>Customize contact options</h3>
            <div className="txt-area-dv">
              <label htmlFor="contactTypeOptions">Requirements options</label>
              <textarea
                name="contactRequirements"
                value={contactOptions.contactRequirements}
                onChange={(e) => handleChange(e, setContactOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="contactType">Type options</label>
              <textarea
                name="contactType"
                value={contactOptions.contactType}
                onChange={(e) => handleChange(e, setContactOptions)}
              />
            </div>
            <div className="txt-area-dv">
              <label htmlFor="contactSourceOptions">Source options</label>
              <textarea
                name="contactSource"
                value={contactOptions.contactSource}
                onChange={(e) => handleChange(e, setContactOptions)}
              />
            </div>

            <button
              style={{
                width: "100%",
                marginTop: "25px",
              }}
              className="btn-save-deal"
              onClick={() => handleSave("Contacts")}
            >
              Save
            </button>
          </div>
        );
      case "Tasks":
        return (
          <div className="tab-content">
            <h3>Customize task options</h3>
            <div className="txt-area-dv">
              <label htmlFor="taskOptions">Task options</label>
              <textarea
                name="taskTypesOptions" // Matches the state key exactly
                value={taskOptions.taskTypesOptions} // Binds the value to the state
                onChange={(e) => handleChange(e, setTaskOptions)} // Passes the event and state setter
                placeholder="Enter task options here"
              />
            </div>

            <button
              style={{
                width: "100%",
                marginTop: "25px",
              }}
              className="btn-save-deal"
              onClick={() => handleSave("Tasks")}
            >
              Save
            </button>
          </div>
        );
      case "Whatsapp":
        return (
          <>
            <section>Hello WhatsApp</section>
          </>
        );
      default:
        return <></>;
    }
  };

  if (isShowSettings) {
    if (user) {
      if (user.role === "admin") {
        return (
          <>
            <section className="mn-sec-csc">
              <div className="mn-dv-csc">
                <div className="tp-br-csc">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <span>Settings</span>
                    <i
                      onClick={() => {
                        setIsShowSettings(false);
                      }}
                      className="fa-solid fa-xmark"
                    ></i>
                  </div>
                  <div className="tab-bar-sts">
                    <ul>
                      <li
                        className={activeTab === "General" ? "active" : ""}
                        onClick={() => setActiveTab("General")}
                      >
                        General
                      </li>
                      <li
                        className={activeTab === "Leads" ? "active" : ""}
                        onClick={() => setActiveTab("Leads")}
                      >
                        Leads
                      </li>
                      <li
                        className={activeTab === "Deals" ? "active" : ""}
                        onClick={() => setActiveTab("Deals")}
                      >
                        Deals
                      </li>
                      <li
                        className={activeTab === "Contacts" ? "active" : ""}
                        onClick={() => setActiveTab("Contacts")}
                      >
                        Contacts
                      </li>
                      <li
                        className={activeTab === "Tasks" ? "active" : ""}
                        onClick={() => setActiveTab("Tasks")}
                      >
                        Tasks
                      </li>
                      <li
                        className={activeTab === "Whatsapp" ? "active" : ""}
                        onClick={() => setActiveTab("Whatsapp")}
                      >
                        Whatsapp Message
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="content-area">{renderContent()}</div>
              </div>
            </section>
          </>
        );
      } else {
        return (
          <>
            <section className="mn-sec-csc">
              <div className="mn-dv-csc">
                <div className="tp-br-csc">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {" "}
                    <span>Settings</span>
                    <i
                      onClick={() => {
                        setIsShowSettings(false);
                      }}
                      className="fa-solid fa-xmark"
                    ></i>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    paddingTop: "55%",
                    color: "white",
                    gap: "5px",
                  }}
                >
                  <i className="fas fa-lock"></i>
                  <span>
                    {" "}
                    Only admin can edit settings and manage permissions.
                  </span>
                </div>
              </div>
            </section>
          </>
        );
      }
    }
  } else {
    return null;
  }
}
