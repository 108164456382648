// TasksOptions.js

let taskOptions = {
  taskTypesOptions: "", // Field to store task types
};

// Function to fetch task options and update the `taskOptions` object
export const fetchTaskOptions = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_API_OPTIONS_TASKS, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Include token if required
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch task options");
    }

    const data = await response.json();
    taskOptions = data.data; // Update the `taskOptions` object with the fetched data
    return taskOptions; // Return updated options for immediate use
  } catch (error) {
    console.error("Error fetching task options:", error);
    taskOptions = null; // If error occurs, set `taskOptions` to null
    return null; // Return null to indicate failure
  }
};

// Export the taskOptions object for direct use
export default taskOptions;
