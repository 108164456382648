import React, { useEffect, useState } from "react";
import "./taskassignment.css";
import taskOptions, { fetchTaskOptions } from "../Data/Tasksoptions";
import CustomSelect from "./CustomeSelect";
import axios from "axios";
import Toast from "./Toast";

export default function TaskAssignment({
  isViewTaskAssignment,
  setIsViewTaskAssignment,
}) {
  // State management
  const [taskName, setTaskName] = useState(""); // Task name
  const [taskStatus, setTaskStatus] = useState(""); // Task status
  const [dueDate, setDueDate] = useState(""); // Due date
  const [assignedTo, setAssignedTo] = useState(""); // Assigned to
  const [comment, setComment] = useState(""); // Comment

  const [relatedTo, setRelatedTo] = useState(); // Related to
  const [isShowToast, setIsShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastMessageClass, setToastMessageClass] = useState("");

  const [tasksOptions, setTasksOptions] = useState(taskOptions); // Task types
  const [users, setUsers] = useState([]); // Users list
  const [loading, setLoading] = useState(true);

  // Fetching options and users on component mount
  useEffect(() => {
    const getOptions = async () => {
      setLoading(true);
      try {
        const fetchedOptions = await fetchTaskOptions();
        if (fetchedOptions) {
          setTasksOptions(fetchedOptions);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    const fetchUsers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("authToken");
        const response = await axios.get(process.env.REACT_APP_API_USERS, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data.users || []);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    getOptions();
    fetchUsers();
  }, []);

  const sendDataAPI = async () => {
    try {
      const data = {
        lead_ID: relatedTo,
        userId: assignedTo,
        task: taskName,
        taskComment: comment,
        taskStatus: taskStatus,
        taskDueDate: dueDate,
      }; // Ensure token is retrieved correctly from localStorage
      const token = localStorage.getItem("authToken");

      if (!token) {
        console.error("No token found in localStorage.");
        return; // Optionally handle error
      }
      axios
        .post(process.env.REACT_APP_API_ADD_TASK, data, {
          headers: {
            Authorization: `Bearer ${token}`, // Send token as Authorization header
          },
        })
        .then((response) => {
          console.log("Data saved successfully:", response.data);
          setToastMessage("Task Saved Successfully");
          setToastMessageClass("success");
          setIsShowToast(true);
          window.location.reload();
        })
        .catch((error) => {
          console.error("Error saving data:", error);
          setToastMessage(
            "Please check entered details and try refreshing page"
          );
          setToastMessageClass("error");
          setIsShowToast(true);
        });
    } catch (error) {}
  };

  if (isViewTaskAssignment) {
    return (
      <section className="mn-sc-unr">
        <div className="mn-dv-unr mn-dv-tskan">
          {/* Top Bar */}
          <div className="topbar-unr topbar-tskasn">
            <h3>Task Assignment</h3>
            <i
              onClick={() => setIsViewTaskAssignment(false)}
              className="fa-solid fa-xmark"
            ></i>
          </div>

          {loading ? (
            <>
              <div className="skelton-container">
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
                <div className="skeleton skeleton-item"></div>
              </div>
            </>
          ) : (
            <>
              {" "}
              {/* Task Assignment Form */}
              <div className="mn-dv-tsk-assign">
                <h3
                  style={{
                    color: "rgb(200,200,200)",
                    paddingLeft: "20px",
                    fontWeight: "400",
                  }}
                >
                  Assign Tasks to Users
                </h3>
                <div className="task-form">
                  {/* Task Name */}
                  <span className="label">
                    Task:
                    <select
                      className="task_new"
                      value={taskName}
                      onChange={(e) => setTaskName(e.target.value)}
                    >
                      <option value="">Assign a new task</option>
                      {tasksOptions.taskTypesOptions?.map((option, index) => (
                        <option key={index} value={option}>
                          {option}
                        </option>
                      ))}
                    </select>
                  </span>

                  {/* Due Date */}
                  <span className="label">
                    Task Due Date:
                    <input
                      type="date"
                      value={dueDate}
                      onChange={(e) => setDueDate(e.target.value)}
                    />
                  </span>

                  {/* Assigned To */}
                  <span className="label">
                    Assigned to:
                    <select
                      value={assignedTo}
                      onChange={(e) => {
                        setAssignedTo(e.target.value);
                        console.log(e.target.value);
                      }}
                    >
                      <option value="">Select User</option>
                      {users.map((user) => (
                        <option key={user._id} value={user._id}>
                          {user.userName}
                        </option>
                      ))}
                    </select>
                  </span>
                  <div className="cstmSLCT">
                    <span className="label">
                      Related to
                      <CustomSelect setSelectedOption_or={setRelatedTo} />
                    </span>
                  </div>
                  {/* Task Status */}
                  <span className="label">
                    Task Status:
                    <select
                      className="styledDropdown"
                      value={taskStatus}
                      onChange={(e) => setTaskStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </span>

                  {/* Comment */}
                  <div className="descriptionContainer">
                    <label>Comment</label>
                    <textarea
                      placeholder="What is this task about?"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>

                  {/* Submit Button */}
                  <button className="saveButton" onClick={sendDataAPI}>
                    Assign Task
                  </button>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginTop: "5px",
                    }}
                  >
                    {isShowToast ? (
                      <Toast
                        message={toastMessage}
                        messageClass={toastMessageClass}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    );
  } else {
    return null;
  }
}
