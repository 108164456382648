// ContactsOptions.js

let contactOptions = {
  contactRequirements: "",
  contactType: "",
  contactSource: "",
};

// Function to fetch contact options and update the `contactOptions` object
export const fetchContactOptions = async () => {
  try {
    const response = await fetch(process.env.REACT_APP_API_OPTIONS_CONTACTS, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`, // Include token if required
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch contact options");
    }

    const data = await response.json();
    contactOptions = data.data; // Update the `contactOptions` object with the fetched data
    return contactOptions; // Return updated options for immediate use
  } catch (error) {
    console.error("Error fetching contact options:", error);
    contactOptions = null; // If error occurs, set `contactOptions` to null
    return null; // Return null to indicate failure
  }
};

// Export the contactOptions object for direct use
export default contactOptions;
